.ShoppingAside {
  display: flex;
  gap: 30px;
}
.ShoppingAside__form {
  width: 48%;
}
.ShoppingAside__paymentType {
  width: 48%;
}
.ShoppingAside__paymentImage {
  display: flex;
  gap: 30px;
  padding: 5px 20px;
}
.ShoppingAside__paymentImage img {
  height: 50px;
}
.ShoppingAside__option {
  cursor: pointer;
  margin: 10px 0;
  padding: 5px 10px 15px;
  border-radius: 10px;
  border: 2px solid #458e7f44;
}
.ShoppingAside__option.checked {
  background: #458e7f80;
}

.ShoppingAside__iframe iframe {
  border: none;
  margin: auto;
  width: 100%;
}
@media screen and (max-width: 900px) {
  .ShoppingAside {
    flex-direction: column;
  }
  .ShoppingAside > div {
    width: 97%;
  }
}
@media only screen and (min-width: 320px) {
  #form-page {
    height: 560px !important;
    /* width: 320px !important; */
  }
}
@media only screen and (min-width: 480px) {
  #form-page {
    height: 560px !important;
    /* width: 480px !important; */
  }
}
@media only screen and (min-width: 768px) {
  #form-page {
    height: 560px !important;
    /* width: 768px !important; */
  }
}
@media only screen and (min-width: 992px) {
  #form-page {
    height: 560px !important;
    /* width: 992px !important; */
  }
}

@media only screen and (min-width: 1200px) {
  #form-page {
    height: 560px !important;
    /* width: 992px !important; */
  }
}
