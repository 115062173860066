.selectTranslate select {
  outline: 0;
  border: none;
  cursor: pointer;
  font-size: 1.6rem;
  -moz-appearance: none;
  text-indent: 0.01px;
}
.selectTranslate {
  background: var(--color3);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 10px 15px;
  color: var(--color6);
  position: relative;
}
