.change {
  font-size: 1.6rem;
}
.change__containerImg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin: auto;
}
#inputFile[type='file'] {
  display: none;
}
.label {
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  font-size: 1.3rem;
}
.imageUp {
  background: var(--orange2);
  color: var(--white);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 10px;
  border: none;
}
.imageUp i {
  margin-left: 5px;
}
.change__btn {
  margin-top: 20px;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 1.6rem;
  color: var(--white);
  background: var(--orange2);
}
.change__btn:disabled {
  display: none;
}
