.page404__home {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  height: 100%;
}

.page404__home-img img {
  width: 50%;
}

.page404__home-text {
  font-size: 20px;
  color: gray;
  margin-top: 50px;
  padding: 5px;
}

.page404__home-text a {
  outline: none;
  text-decoration: none;
  background: #ee7b35;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  padding: 12px 32px;
}
.page404__home-text a:hover {
  background: #469585;
}
