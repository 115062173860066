.CourseAside h2 {
  font-size: 2rem;
  margin-top: 0;
}
.ModuleLink__header {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  cursor: pointer;
}
.ModuleLink__header i {
  font-size: 1.4rem;
}
