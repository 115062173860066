.ShortCourses__marginTop {
  height: 90px;
}

.ShortCourses__header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.ShortCourses__Htext {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.ShortCourses__Hbtns {
  display: flex;
  gap: 10px;
}

.ShortCourses__Hbtns .DefaultBtn {
  font-size: 2rem;
  font-weight: 600;
  width: fit-content;
  padding: 20px 30px;
  border-radius: 30px;
  border: none;
}

.ShortCourses__Hbtns .DefaultBtn:last-child {
  background: var(--purple);
  color: var(--white);
}

.ShortCourses__Himage {
  margin: 0 auto;
  align-items: center;
  width: 450px;
  height: 450px;
  position: relative;
}

.ShortCourses__Imagecontainer {
  margin: auto;
  overflow: hidden;
  width: 95%;
  align-items: center;
}

.ShortCourses__Imagecontainer ul {
  display: flex;
  padding: auto;
  width: 400%;
  animation: display 12s infinite alternate linear;
}

.ShortCourses__Imagecontainer li {
  width: 200%;
  list-style: none;
}

.ShortCourses__Imagecontainer img {
  width: 80%;
  margin-right: 20px;
  height: auto;
}

@keyframes display {
  0% {
    margin-left: 0%;
    opacity: 1;
  }

  20% {
    margin-left: 0%;
    opacity: 1;
  }

  25% {
    margin-left: -100%;
    opacity: 1;
  }

  45% {
    margin-left: -100%;
    opacity: 1;
  }

  50% {
    margin-left: -200%;
    opacity: 1;
  }

  70% {
    margin-left: -200%;
    opacity: 1;
  }

  75% {
    margin-left: -300%;
    opacity: 1;
  }

  100% {
    margin-left: -300%;
    opacity: 1;
  }
}

.ShortCourses__Himage .ShortCourses__Imageonly {
  width: 450px;
  height: 350px;
  margin: 20px auto;
}

.ShortCourses__Himage .ShortCourses__Imageonly img {
  width: 80%;
  height: auto;
  margin: 50px;
}

.ShortCourses__Hsquare {
  border-radius: 35px;
  box-shadow: 2px 2px 2px gray;
}

.ShortCourses__courses {
  display: flex;
}

.TitleSquare .H3 {
  writing-mode: vertical-rl;
  padding: 10px 0px 10px 10px;
}

.TitleSquare__card {
  background: var(--purple);
  border-radius: 0 30px 30px 0;
  transform: translateY(15px);
  padding: 10px 0px 0px 0px;
}

.ShortCourses__list {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px 15px;
}

.CardShortCourse {
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.CardShortCourse__image {
  width: 100%;
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
}

.CardShortCourse__content {
  padding: 10px;
}

.CardShortCourse__teacher {
  display: flex;
  align-items: center;
  gap: 10px;
}

.CardShortCourse__photo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-size: cover;
}

.ShortCourses__advantages {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px 30px;
}

.ShortCourseBenefit {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 10px 20px;
  margin: 0 auto;
}

.ShortCourseBenefit i {
  font-size: 5rem;
  min-width: 70px;
  color: var(--purple);
}

.ShortCourses__category {
  display: flex;
  gap: 10px;
}

.ShortCourses__listCategory {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 50px;
}

.ShortCourses__cardCategory {
  width: 200px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 10px;
  border-radius: 10px;
  text-decoration: none;
  /* background: linear-gradient(0deg, #762296 0%, #75229665 100%); */
}

.ShortCourses__cardCategory img {
  width: 150px;
}

.ShortCourses__section {
  display: flex;
}

.ShortCourses__section img {
  border-radius: 30px;
}

.ShortCourses__teachers {
  display: flex;
  gap: 10px;
}

.ShortCourses__teachersList {
  gap: 10px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.ShortCourses__Banner {
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 10px 40px;
  background: linear-gradient(90deg, rgb(118, 34, 150) 0%, #5cd6bd 100%);
}

.ShortCourses__Btext {
  text-align: center;
}

.CardTeacherFeatured {
  width: 280px;
  height: 360px;
  border-radius: 30px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background: rgb(156, 156, 156);
  position: relative;
  transition: 0.4s ease-out;
}

.CardTeacherFeatured:hover {
  transform: translateY(20px);
}

.CardTeacherFeatured:hover:before {
  opacity: 1;
}

.CardTeacherFeatured:hover .CardTeacherFeatured__description {
  display: block;
  opacity: 1;
  transform: translateY(0px);
}

.CardTeacherFeatured:hover .CardTeacherFeatured__image {
  filter: blur(5px);
}

.CardTeacherFeatured:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}

.CardTeacherFeatured__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.CardTeacherFeatured__text {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  border-radius: 0 0 15px 15px;
}

.CardTeacherFeatured__description {
  position: absolute;
  z-index: 3;
  color: white;
  left: 0;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
  display: none;
}

@media screen and (max-width: 768px) {
  .ShortCourses__Himage {
    width: 250px;
    height: 250px;
  }

  .ShortCourses__marginTop {
    height: 180px;
  }

  .AdvancedCourses__marginTop {
    height: 100px;
  }

  .ShortCourses__header img {
    width: 200px;
  }

  .ShortCourses__Himage .ShortCourses__Imageonly {
    width: auto;
    height: auto;
    margin: auto, 0;
  }

  .ShortCourses__Himage .ShortCourses__Imageonly img {
    width: 200px;
    height: auto;
    margin: 30px;
  }

  .ShortCourses__header {
    flex-direction: column-reverse;
    gap: 100px 0;
  }

  .ShortCourses__Htext {
    width: 80%;
  }

  .ShortCourses__courses {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px 0;
  }

  .TitleSquare .H3 {
    writing-mode: horizontal-tb;
    width: fit-content;
    padding: 30px 25px 30px 10px !important;
  }

  .ShortCourses__advantages {
    grid-template-columns: 1fr;
  }

  .ShortCourses__category {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px 0;
  }

  .ShortCourses__section {
    flex-direction: column-reverse;
    gap: 100px 0;
  }

  .ShortCourses__section img {
    width: 200px;
  }

  .ShortCourses__Banner {
    flex-direction: column;
  }
}

@media screen and (max-width: 425px) {
  .ShortCourses__marginTop {
    height: 130px;
  }
}

.CardShortCourseMax {
  width: 420px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  background: rgba(255, 255, 255, .7);
  background: var(--purple);
  text-align: center;
  color: white;
  font-size: 15px;
  transition: all 0.15s ease;
}

.Card_list {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  grid-gap: 35px;
}


.CardImageHeader {
  position: relative;
}

.CardImageSection {
  position: relative;
}

.bg-front-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(118, 34, 150, .3);
  background: linear-gradient(rgba(255, 255, 255, .2) 10%, rgba(118, 34, 150, .6));
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.icon-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: right;
  z-index: 1;
}

.icon-badge i {
  color: gold;
  font-size: 40px;
  background-color: transparent;
}

.CardShortCourseMax:hover {
  background: rgba(255, 255, 255, .9);
  color: var(--purple);
}

.AdvancedCourses__marginTop {
  height: 50px;
}

.margin-body {
  height: 80px;
}

@media screen and (max-width: 425px) {
  .Card_list {
    grid-template-columns: repeat(1, auto);
    justify-content: center;
    gap: 20px 20px;
  }

  .CardShortCourseMax {
    width: 320px;
  }
}

@media screen and (max-width: 920px) {
  .Card_list {
    grid-template-columns: repeat(1, auto);
  }
}

@media screen and (max-width: 1200px) {
  .AdvancedCourses__marginTop {
    height: 15px;
  }

  .margin-body {
    height: 30px;
  }
}