.HomeworkAside h2 {
  font-size: 2rem;
}
.HomeworkAside__space {
  height: 50px;
}
.HomeworkAside__upload input {
  display: none;
}
.HomeworkAside__upload label {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  padding: 15px 0;
  border-radius: 5px;
  color: var(--orange2);
  background: var(--white);
  cursor: pointer;
  justify-content: space-around;
  font-size: 1.6rem;
  display: inline-block;
  width: 100%;
}
.HomeworkAside__upload span {
  margin-right: 10px;
}
.HomeworkAside__links h3 {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 0 10px;
}
.HomeworkAside__links .HomeworkAside__doc {
  margin: 15px;
}
.HomeworkAside__h3 {
  background: var(--white);
  padding: 10px 0;
  border-radius: 5px;
  color: var(--green);
}
.HomeworkAside__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
.HomeworkAside__link a {
  font-size: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: start;
  padding: 10px;
  text-decoration: none;
  background: var(--white);
  border-radius: 5px;
  color: var(--green);
  font-weight: 500;
}
.HomeworkAside__link i {
  font-size: 2rem;
  color: #e3ad2d;
  background: #e3ad2d3f;
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  border-radius: 5px;
  cursor: pointer;
}
.HomeworkAside__link i:hover {
  background: #e3ad2d1c;
}
.HomeworkAside__link a:hover {
  color: var(--black);
}
