.CourseCard__main {
  /* background: var(--white); */
  /* border: 1px var(--white) solid; */
  /* box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); */
  /* border-radius: 10px; */
  /* width: 100%; */
  /* margin-right: 10px; */
  text-decoration: none;
  /* color: #494949; */
}
/* .CourseCard__main:hover { */
/* border: 1px var(--green) solid; */
/* } */
/* .CourseCard__options {
  background: var(--white);
  border: 1px var(--white) solid;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 8%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
} */
/* .CourseCard__options:hover {
  border: 1px var(--green) solid;
} */
.CourseCard__title {
  margin: 15px 10px 0;
  font-size: 1.8rem;
}
.CourseCard__information {
  font-size: 1.6rem;
  margin: 10px 10px 5px;
}
.CourseCard__information span {
  color: #494949;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px #494949 solid;
}
.CourseCard__progres {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 15px 10px;
}
.CourseCard__progres span {
  margin: 0 10px;
  font-size: 1.6rem;
  color: #494949;
}
