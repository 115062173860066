.IntputNumber {
  display: flex;
  justify-content: space-between;
  border: solid 1px rgb(216, 216, 216);
  margin: 8px 0;
  border-radius: 10px;
  padding: 10px 15px 4px 10px;
  color: var(--color3);
  font-size: 1.6rem;
}
.number-input input {
  text-align: center;
  width: 50px;
  border-radius: 40px;
  border: solid 2px var(--color8);
  margin-bottom: 5px;
}
