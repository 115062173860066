.ViewHeader__head {
  height: calc(100vh - 100px);
  position: relative;
}
.ViewHeader__head .reactPlayerC video {
  background: var(--white);
}
.ViewHeader__head .video2 {
  display: none;
}
.ViewHeader__img {
  display: none;
}
.ViewHeader__background {
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100vh - 100px);
}
.ViewHeader__card {
  position: absolute;
  right: 20px;
  top: 50%;
}
.ViewHeader__text {
  display: none;
}
.ViewHeader__main {
  display: flex;
  height: 100px;
}
.CardCourse {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CardCourse:hover {
  filter: brightness(1.2);
}
.CardCourse:hover img {
  height: 60px;
  transition-duration: 0.5s;
}
.CardCourse img {
  height: 55px;
}
@media screen and (max-width: 1024px) {
  .ViewHeader__head .video1 {
    display: none;
  }
  .ViewHeader__head .video2 {
    display: block;
  }
}
@media screen and (max-width: 900px) {
  .ViewHeader__head .reactPlayerC {
    display: none;
  }
  .ViewHeader__img {
    display: block;
    position: absolute;
    background-image: url('https://res.cloudinary.com/pataformacgi/image/upload/v1655472645/assets/portadaIngles_kgnlnq_t19b7f.png');
    width: 100%;
    top: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .ViewHeader__text {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .ViewHeader__card {
    top: 75px;
    right: 0;
    left: 0;
  }
  .CardCourse {
    padding: 0;
    width: 100% !important;
    height: 100px;
  }
}
