.CardKid {
  background: var(--white);
  padding: 10px;
  border-radius: 15px;
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--white);
}
.CardKid:hover {
  border: 1px solid var(--green);
}
.CardKid__photo {
  width: 60px;
  height: 60px;
  background-size: contain;
}
