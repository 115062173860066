.Registered {
  margin-top: 15px;
}
.Registered__description {
  width: 70%;
  margin: auto;
  padding: 100px 0;
}
.Registered__step {
  background-color: #fdf4e5;
  padding: 30px;
}
.Registered__stepContainer {
  display: flex;
  gap: 10px;
}
.Registered__list {
  width: 60%;
}
.Registered__images {
  width: 40%;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-around;
  flex-direction: column;
}
.Registered__images img {
  width: 80%;
  border-radius: 15px;
}
.Registered__footer {
  background-color: #ff914d;
  padding: 20px 0;
}
.Registered__footer a {
  color: #3661f7;
}
@media screen and (max-width: 425px) {
  .Registered__images {
    display: none;
  }
  .Registered__list {
    width: auto;
  }
}
