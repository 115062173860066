.CourseInformation {
  margin-top: 45px;
  background-image: url('https://res.cloudinary.com/dsgaf5zoj/image/upload/v1622071295/assets/k1gqfw3nlvos28qjh9hl.png');
  background-size: cover;
  background-position: center;
}
.CourseInformation__icon {
  margin: 20px;
}
.CourseInformation__icon i {
  font-size: 3rem;
  color: var(--purple);
}
.CourseInformation .Section {
  text-transform: uppercase;
}
.CourseInformation .Section ul {
  margin: 0;
}
.ColorLine {
  height: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.ColorLine div {
  width: calc(100% / 4);
}
.ColorLine div:first-child {
  width: 100%;
}
.CourseInformation__flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.CourseInformation__flex2 {
  display: flex;
  justify-content: space-around;
}
.CourseInformation__image {
  width: 40%;
}
.CourseInformation__image img {
  width: 100%;
}
.CourseInformation__section {
  width: 50%;
}
.CourseInformation__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.CourseInformation__header svg {
  height: 50px;
}
.CourseInformation__btnContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 10px;
}
.CourseInformation__btnContainer a {
  height: 200px;
}
.CourseInformation__btnContainer img {
  height: 100%;
}
.CourseInformation__text {
  width: 50%;
}
.CourseInformation__rectangle {
  background: var(--white);
  width: 80%;
  margin: 20px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px 10px 0 10px;
}
.CourseInformation__Rborder {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 20px;
  transform: scaleY(1.3);
}
.CourseInformation__Rborder .Section {
  transform: scaleY(0.7);
}
.CourseInformation__Rborder .H2 {
  white-space: nowrap;
  transform: scaleY(0.7);
}
.EnglishInformation__footer {
  display: flex;
  justify-content: space-around;
  margin: 50px 0;
}
.CardGreen {
  position: absolute;
  width: 300px;
  height: 300px;
}

.CardGreen .cls-1,
.CardGreen .cls-2,
.CardGreen .cls-4 {
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

.CardGreen .cls-1 {
  stroke: #ee7c38;
}

.CardGreen .cls-2 {
  stroke: #458e7f;
}

.CardGreen .cls-3 {
  fill: #fff;
}

.CardGreen .cls-4 {
  stroke: #6a3081;
}
.CardGreen__container {
  position: relative;
  width: 300px;
  height: 300px;
}
.CardGreen__text {
  position: absolute;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--purple);
}
.CardGreen__text p {
  width: 200px;
  font-size: 1.3rem;
}
.CardGreen__text i {
  font-size: 3rem;
}
.CourseInformation__btn {
  color: var(--white);
  font-size: 1.6rem;
  padding: 8px 20px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}
.CourseInformation__btn i {
  font-size: 2.5rem;
}
.CourseInformation__btn.green {
  background-color: #48bd4c;
}
.CourseInformation__btn.blue {
  background-color: #3465d8;
}
@media screen and (max-width: 1130px) {
  .CourseInformation__flex2 {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .CourseInformation__image {
    width: 70%;
  }
  .CourseInformation__section {
    width: 100%;
  }
  .CourseInformation__flex {
    align-items: center;
    flex-direction: column;
  }
  .CourseInformation .Section {
    font-size: 1.5rem !important;
  }
  .CourseInformation__Rborder {
    flex-direction: column;
    gap: 0;
  }
  .CourseInformation__Rborder .H2 {
    width: auto;
  }
  .CourseInformation__btnContainer {
    flex-direction: column;
  }
  .CourseInformation .Section {
    text-transform: none;
  }
  .CourseInformation__text {
    width: auto;
  }
  .EnglishInformation__footer {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .CourseInformation__flex3 {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
  }
}
