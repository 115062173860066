.CourseContent__lessons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.CourseContent__lessons a {
  text-decoration: none;
  border: 1px solid var(--green);
  padding: 10px;
  border-radius: 10px;
}
.CourseContent__lessons a:hover {
  opacity: 0.8;
  box-shadow: 0 0 4px var(--green);
}
