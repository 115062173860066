.ContainerKid__photo {
  width: 90px;
  height: 90px;
  background-size: contain;
}
.ContainerKid__buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.KidCourse {
  background: var(--white);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.KidCourse__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0;
}
.KidCourse__head:hover .H3 {
  color: var(--green) !important;
}
.KidCourse__listModule {
  display: flex;
  gap: 10px;
}
.KidCourse__listModule button {
  width: 100%;
  padding: 10px 0;
  font-weight: 600;
  font-size: 1.6rem;
  border-radius: 10px;
  border: 1px solid var(--green);
  color: var(--white);
  cursor: pointer;
}
.ModuleDetail {
  background: #f8f8f8;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
}
.CardTracing {
  width: 250px;
  padding: 10px;
  border-radius: 10px;
  background: var(--white);
}
