.HomeworkContent__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
.HomeworkContent__btns {
  display: flex;
  gap: 5px;
}
.ListExtensions__weighing {
  background-color: var(--white);
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(61, 61, 61);
}
.HomeworkContent__btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.HomeworkContent__btn button {
  width: 50%;
}
.HomeworkContent__status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.HomeworkContent__grades {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 10px 0 15px;
}
.HomeworkContent__fileInf {
  border: 1px solid var(--gray);
  padding: 5px 10px;
  display: flex;
  gap: 15px;
  border-radius: 10px;
}
.HomeworkContent__fileInf div {
  text-align: center;
}
.HomeworkContent__Qualification {
  text-align: center;
  padding: 10px 20px;
}
.HomeworkContent__Qualification:last-child {
  border-radius: 0 10px 10px 0;
}
.HomeworkContent__Qualification:first-child {
  border-radius: 10px 0 0 10px;
}
.HomeworkContent__Qualification.correct {
  background: #4695853f;
}
.HomeworkContent__Qualification.wrong {
  background: #c93f3f3f;
}
.HomeworkContent__grades .HomeworkContent__hr {
  height: 20px;
  border: rgba(0, 0, 0, 0.4) 1px solid;
}
@media screen and (max-width: 576px) {
  .HomeworkContent__head {
    flex-direction: column;
  }
  .HomeworkContent__status {
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 10px;
  }
  .HomeworkContent__grades {
    flex-direction: column;
  }
  .HomeworkContent__Qualification {
    width: 100%;
  }
  .HomeworkContent__Qualification:last-child {
    border-radius: 0 0 10px 10px;
  }
  .HomeworkContent__Qualification:first-child {
    border-radius: 10px 10px 0 0;
  }
  .HomeworkContent__grades .HomeworkContent__hr {
    width: 50%;
    height: auto;
  }
  .HomeworkContent__btn {
    flex-direction: column;
    gap: 15px;
  }
  .HomeworkContent__btn button {
    width: 100%;
  }
}
