.Calendar {
  width: 100%;
  background: var(--white);
  border-radius: 10px;
}
.Calendar__body {
  padding: 10px;
}
.Calendar__header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 44px;
  font-size: 1.6rem;
}
.Calendar__header i {
  cursor: pointer;
  width: 20px;
}
.Calendar__month {
  height: auto;
}
.Calendar__header span {
  margin: 0 5px;
  text-transform: uppercase;
}
.Calendar__week {
  color: var(--green);
}
.Calendar__day {
  width: calc(100% / 7);
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 3px;
}
.Calendar__day .Calendar__text {
  color: var(--black);
  line-height: 44px;
  background: var(--white2);
  border-radius: 5px;
}

.Calendar__day .Calendar__text.before {
  color: gray;
}
.Calendar__day .Calendar__text .Calendar__point {
  border-radius: 50%;
  padding: 5px;
  background: var(--green);
  color: var(--white);
}
.Calendar__day .Calendar__text.selected {
  background-color: var(--orange1);
  color: var(--white);
  font-weight: bold;
}

.Calendar__day .Calendar__text.today {
  background-color: var(--green);
}
.Calendar__day .Calendar__text.today .Calendar__point {
  background-color: var(--white);
}
.Calendar__events {
  margin: 0 20px;
  /* border: 1px solid red; */
}
