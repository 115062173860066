.Seminar__modal {
  background: var(--white);
  padding: 20px 10px;
  border-radius: 10px;
  text-align: center;
  width: 50%;
  margin: 0 auto;
}
.Seminar__modal h3 {
  font-size: 2rem;
}
.Seminar__modal h4 {
  font-size: 1.6rem;
  font-weight: 400;
}
.Seminar__modal .NormalButton {
  width: 150px;
}
.Seminar__modal .Seminar__Mbtns {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
}
.Seminar__modal .Seminar__Mbtns button {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .Seminar__modal {
    width: 90%;
  }
  .Seminar__Mbtns {
    flex-direction: column;
  }
  .Seminar__modal .Seminar__Mbtns button {
    margin: 0;
  }
}
