.CardPink {
  /* width: 150px; */
  /* min-width: 150px; */
  /* background: var(--white); */
  /* border: 1px solid var(--black); */
  /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.16); */
  border: solid 1px rgba(0, 0, 0, 0.2);
  margin: 5px 0;
  border-radius: 10px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.CardPink__title {
  font-weight: bold;
}
