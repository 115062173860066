.CompModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.CompModal__close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 1rem;
  background-color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
  z-index: 2;
}
.CompModal__container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CompModal__video {
  min-width: 60%;
  width: 70%;
  position: absolute;
  z-index: 1;
  max-height: 100vh;
  overflow-y: auto;
}
.CompModal__video::-webkit-scrollbar {
  width: 1rem;
}
.CompModal__video::-webkit-scrollbar-track {
  margin: 10px 0;
}
.CompModal__video::-webkit-scrollbar-thumb {
  background: var(--green);
  border-radius: 25px;
}
.CompModal__close {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 0;
}
@media (max-width: 680px) {
  .CompModal__video {
    width: 100%;
  }
  .CompModal__close-button {
    border: 1px solid var(--black);
  }
}
