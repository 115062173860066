.GetAnswer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.GetAnswer__query {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 10px;
}
.GetAnswer__questions h2 {
  margin: 0;
  font-size: 1.6rem;
  font-weight: 500;
}
