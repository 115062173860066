.QuizContent {
  margin: 15px 2%;
  font-size: 2rem;
}
.QuizContent__header {
  text-align: end;
}
.QuizContent__chronometer {
  display: flex;
  justify-content: space-between;
}
.QuizContent__chronometer span {
  display: flex;
  align-items: center;
}
.QuizContent__chronometer i {
  margin-right: 10px;
  font-size: 3rem;
}
.QuizContent__form {
  width: 900px;
  margin: 0 auto;
  text-align: center;
}
.QuizContent__question {
  text-align: end;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
}
.QuizContent__question label {
  font-size: 1.6rem !important;
}
.QuizContent__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.QuizContent__header h3 {
  font-size: 1.6rem;
  font-weight: normal;
}
.QuizContent__question {
  padding: 20px;
}
.QuizContent__modal {
  background: var(--white);
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}
.QuizContent__modal h2,
h3 {
  margin: 0;
}
.QuizContent__modal h3 {
  font-weight: 500;
  margin-top: 8px;
}
.QuizContent__Mbtns {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .QuizContent__header {
    flex-direction: column-reverse;
  }
  .QuizContent__question {
    padding: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .QuizContent__form {
    width: 100%;
  }
}
