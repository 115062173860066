.styleDefault .cls-1 {
  fill: #f8fcfc;
}
.styleDefault .cls-2 {
  fill: none;
  stroke: #007e6c;
  stroke-miterlimit: 10;
  stroke-width: 7px;
}
.styleDefault .cls-3 {
  filter: url(#Path_4243);
}

.style4 .cls-1,
.style4 .cls-3 {
  fill: #6a3081;
}

.style4 .cls-2 {
  fill: #f6f1f8;
}

.style4 .cls-3 {
  opacity: 0.98;
}
