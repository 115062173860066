.InstitutionPrereg {
  font-size: 1.6rem;
}
.InstitutionPrereg__information h4 {
  text-align: start;
  color: rgba(0, 0, 0, 0.5);
  margin: 15px 0 5px;
}
.InstitutionPrereg__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* transform: translateY(-5px); */
}
.InstitutionPrereg__btns button {
  font-size: 1.6rem;
  padding: 8px 0;
  width: 100%;
}
.InstitutionPrereg__btns .Loading {
  width: 100%;
}
.CardInformation {
  transform: translateY(-15px);
}
.CardInformation__title .H3 {
  background: var(--white2);
  transform: translate(10px, 10px);
  width: fit-content;
}
.CardInformation__main {
  border: 2px solid gray;
  padding: 15px 5px 5px;
  border-radius: 10px;
}
.CardInformation__btn {
  transform: translateY(-12px);
}
.CardInformation__btn button:hover {
  opacity: 1;
  background: #7e7980 !important;
}
