.GlobalModal {
  background: var(--white);
  width: fit-content;
  margin: 0 auto;
  padding: 10px 15px;
  border-radius: 10px;
  text-align: center;
}
.GlobalModal h2 {
  font-size: 2rem;
  margin: 0 0 5px;
}
.GlobalModal__p {
  font-size: 1.6rem;
  margin: 0 0 15px;
}
.GlobalModal__btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
/* .GlobalModal .NormalButton {
  width: fit-content;
  padding: 5px 20px;
} */
@media screen and (max-width: 425px) {
  .GlobalModal {
    width: 80%;
  }
  .GlobalModal .GlobalModal__btns {
    gap: 20px;
    gap: 10px;
  }
  .GlobalModal button {
    width: 100%;
  }
}
