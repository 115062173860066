.title__option {
  color: var(--grey);
  font-weight: bold;
  text-decoration: none;
  font-size: 2rem;
  border-radius: 5px;
  padding: 5px;
}
.title__option:hover {
  filter: grayscale(10%) opacity(0.8);
  background: #6a308180;
  box-shadow: 0px 0px 1px 1px #6a3081;
}
.LoginForm__titleAc {
  color: #6a3081;
  filter: grayscale(0%) opacity(1);
}
.LoginForm__titleSpan {
  color: #6a3081;
}
.LoginForm__external {
  padding-left: 8%;
  padding-right: 8%;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-content: center;
}
.LoginForm__fg {
  display: flex;
  width: auto;
}
.Login_separated {
  height: 60%;
  width: 1px;
  margin: auto 0;
  background-color: gray;
}
.LoginForm__form {
  text-align: center;
}
@media screen and (max-width: 425px) {
  .Login_separated {
    display: none;
  }
}
