.TableHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 10px;
  text-align: center;
}
.TableHeader i {
  font-size: 1.2rem;
}
