.SeminarAside .SeminarAside__title {
  font-size: 2.5rem;
  margin-bottom: 0;
}
.SeminarAside .SeminarAside__subTitle {
  font-size: 2rem;
  text-align: start;
  margin: 10px 0 5px;
  font-weight: 600;
  padding: 0;
}
.SeminarAside p {
  font-size: 1.6rem;
  text-align: justify;
  padding: 15px;
  border-radius: 10px;
  background: var(--white);
  color: gray;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  margin: 0 0 10px;
}
