.Questions {
  width: 800px;
  margin: 0 auto;
}
.QuestionContainer {
  margin-bottom: 15px;
}
.QuestionContainer__header {
  display: flex;
  cursor: pointer;
  font-size: 1.6rem;
  color: #5f5961;
}
.QuestionContainer__header .H4 {
  color: #5f5961;
}
@media screen and (max-width: 900px) {
  .Questions {
    width: auto;
    padding: 0 20px;
  }
}
