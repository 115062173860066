.SimpleCard__container {
  gap: 20px;
  height: 90%;
  display: flex;
  padding: 10px;
  /* align-items: center; */
  border-radius: 10px;
  background: white;
  border: 1px solid white;
  justify-content: space-between;
  transition: box-shadow 0.5s;
}

.SimpleCard__container:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
}

.SimpleCard__main {
  gap: 20px;
  display: flex;
  align-items: center;
}

.course_info {
  color: #757a83;
}

.SimpleCard__image {
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.SimpleCard__description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
}

.SimpleCard__description .DefaultBtn {
  font-size: 1.4rem;
  width: max-content;
  background-color: #ee7e34;
  border: 1px solid #ee7e34;
}

@media screen and (max-width: 500px) {
  .SimpleCard__container {
    width: auto;
    padding: 5px;
    flex-direction: column;
    padding-bottom: 15px;
  }

  .SimpleCard__description {
    flex-direction: row;
    height: auto;
    gap: 5px 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.badge-card {
  box-sizing: border-box;
  position: relative;
}

.badge-card::before {
  position: absolute;
  top: -19px;
  left: -46px;
  display: block;
  box-sizing: border-box;
  content: "¡Nuevo!";
  text-transform: uppercase;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  color: #fff;

  background: transparent;
  height: 0px;
  border: 25px solid transparent;
  border-bottom: 25px solid rgb(219, 34, 34);
  line-height: 23px;
  transform: rotate(-45deg);

  box-shadow: 0 4px 2px -2px gray;
}

@media screen and (max-width: 500px) {
  .badge-card::before {
    top: -14px;
    left: -41px;
  }
}