.LoadingScreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: var(--white);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoadingScreen__main {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoadingScreen__logo {
  position: absolute;
}
.LoadingScreen__circle {
  position: absolute;
}
.LoadingScreen__circle2 {
  position: absolute;
}
