.CircleBar {
  text-align: center;
}
.CircleBar__main {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  font-size: 1.6rem;
  background: var(--white);
  cursor: pointer;
  margin: 3px 0;
}
.CircleBar i {
  font-size: 1.5rem;
  color: #494949;
  height: 15px;
}
.icondisabled {
  height: 15px;
}
.CircleBar__date {
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: 500;
}
