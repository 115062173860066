.HomeHero {
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  padding-top: 70px;
  display: flex;
  overflow-y: hidden;
}
.HomeHero a {
  text-decoration: none;
}
.HomeHero__cgi {
  width: 50%;
  background-image: url('https://res.cloudinary.com/pataformacgi/image/upload/v1655472642/assets/WhatsApp_Image_2022-06-03_at_12.01.21_PM_rzmfpg_uicajl.jpg');
  background-size: cover;
}
.HomeHero__cgiCard {
  padding: 0 0 35px 0;
}
.CardHero {
  border-radius: 10px;
}
.HomeHero__cgiCardImg {
  height: 200px;
  padding: 20px 30px;
  transform: rotateZ(15deg);
}
.HomeHero__cgiimg {
  background-color: #b052bd8e;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.HomeHero__logo {
  height: 100px;
}
.HomeHero__cgikids {
  width: 50%;
  background-image: url('https://res.cloudinary.com/pataformacgi/image/upload/v1655472642/assets/WhatsApp_Image_2022-06-03_at_12.01.20_PM_hgug4c_dut2qm.jpg');
  background-size: cover;
}
.HomeHero__cgikidsimg {
  background-color: #ff5e00d7;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.HomeHero__kidsImg {
  width: 350px;
}
.HomeHero__kidsBtn {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.HomeHero .RenderButton {
  font-size: 3rem;
  padding: 20px;
  border-radius: 10px;
  font-weight: 600;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}
.HomeHero__kidsBtn img {
  height: 300px;
}
.HomeHero__kidsRenderBtn {
  display: none;
}
@media screen and (max-width: 900px) {
  .HomeHero {
    height: auto;
    padding-top: 70px;
    flex-direction: column;
    overflow-y: auto;
  }
  .HomeHero > a {
    width: 100%;
  }
  .HomeHero__cgiCard {
    padding: 100px 0;
  }
  .HomeHero__kidsRenderBtn {
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .HomeHero__kidsRenderBtn {
    margin-bottom: 20px;
  }
  .HomeHero__kidsBtn .RenderButton {
    display: none;
  }
  .HomeHero__kidsRenderBtn {
    display: block;
  }
}
