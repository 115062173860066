.Catalogue__title .H2 {
  text-transform: uppercase;
  color: gray;
}
.Catalogue__head {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.Catalogue__navigation {
  margin: 15px 0 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 10px;
}
@media screen and (max-width: 768px) {
  .Catalogue__head {
    flex-direction: column;
  }
}
