.UnitsAside {
  background: var(--white);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  margin-bottom: 5px;
  border-radius: 10px;
  text-align: start;
  padding: 15px;
}
.UnitsAside__header {
  color: var(--green);
  display: flex;
  justify-content: space-between;
}
.UnitsAside__header i {
  margin-left: 10px;
  cursor: pointer;
}
.UnitsAside__head {
  display: flex;
  gap: 10px;
  align-items: center;
}
.UnitsAside__progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.UnitsAside__circle {
  width: 20px;
  height: 20px;
  min-height: 20px;
  border: 3px solid var(--green);
  border-radius: 50%;
}
.UnitsAside__line {
  background: var(--green);
  width: 3.5px;
  height: 100%;
}
.UnitsAside__title {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 500;
}
.UnitsAside a {
  text-decoration: none;
}
.Lection__header {
  display: flex;
  gap: 15px;
  color: #5d5d5d;
}
.Lection__header-activate {
  display: flex;
  gap: 15px;
  color: var(--orange2);
}
.Lection__progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 6px;
}
.Lection__circle {
  width: 15px;
  height: 15px;
  min-height: 15px;
  background: var(--green);
  border-radius: 50%;
}
.Lection__line {
  background: var(--green);
  width: 3px;
  height: 100%;
}
.Lection__lineT {
  background: transparent;
  width: 3px;
  height: 100%;
}
.Lection__title {
  font-size: 1.4rem;
  padding: 10px 0;
}
.UnitsAside__lockcontent {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #5d5d5d;
}
