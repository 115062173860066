.CardPunctuation {
  border-radius: 10px;
  padding: 15px;
  width: 320px;
  text-align: start;
  margin-bottom: 20px;
}
.CardPunctuation::before {
  transition: 1s;
}
.CardPunctuation::after {
  transition: 1s;
}
.CardPunctuation__header {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #ee7c38;
}
.CardPunctuation__Havatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}
.CardPunctuation__Hname {
  margin: 0;
  font-size: 1.8rem;
  color: #000000;
}
.CardPunctuation__main {
  font-size: 1.5rem;
  color: var(--black);
  text-align: justify;
}
.CardPunctuation__span {
  color: #ee7c38;
  font-size: 1.6rem;
}
@media screen and (max-width: 425px) {
  .CardPunctuation {
    width: 100%;
    padding: 4px 2px 4px 2px;
  }
}
