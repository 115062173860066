.Sidebar__icon1 {
  position: absolute;
  top: 20px;
  z-index: 3;
  color: var(--purple);
  cursor: pointer;
}
.Menu__nav {
  background: white;
  width: 180px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 20px 20px 40px 40px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  border-radius: 0 10px 10px 0;
}
.Menu__close {
  position: absolute;
  width: 100vw;
  z-index: 1;
  height: 100vh;
  background: rgba(255, 255, 255, 0.4);
  top: 0;
  left: 0;
}
.Menu__liks {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  margin-top: 30px;
}
.Menu__liks .LinkNav {
  font-size: 2.5rem;
}
@media screen and (max-width: 425px) {
  .Menu__nav {
    height: 100vh;
    border-radius: 0;
    margin-top: 0;
    padding: 0;
    width: 100%;
  }
  .Menu__liks {
    margin-top: 0;
    align-items: center;
    justify-content: center;
  }
  .Menu__liks .LinkNav {
    font-size: 3rem;
  }
}
