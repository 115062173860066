.ChangePassword {
  font-size: 1.6rem;
}
.ChangePassword__btn {
  display: flex;
  width: 100%;
}
.ChangePassword__btn button {
  font-size: 1.6rem;
}
.ChangePassword__btnC {
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  height: 40px;
  background: var(--white);
  color: var(--black);
  padding: 0 30px;
}
