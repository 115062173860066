.ButtonIconDelete {
  border: none;
  font-size: 2rem;
  background: none;
  color: #d17742;
  cursor: pointer;
  float: right;
}
.ButtonIconDelete:hover {
  color: var(--green);
}
.ButtonIconDelete:disabled{
  border: none;
  font-size: 2rem;
  background: none;
  color: #585858;
  cursor: default;
  float: right;
}