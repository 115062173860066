.ButtonIcon {
  border: none;
  font-size: 2rem;
  background: none;
  cursor: pointer;
}
.ButtonIcon__content {
  text-align: center;
}
.ButtonIcon:hover {
  opacity: 0.7;
  /* color: var(--green); */
}

.BackButton {
  border: none;
  background: var(--green);
  border-radius: 5px;
  color: white;
  padding: 1.5rem;
  cursor: pointer;
  transition: 0.3s ease all;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.BackButton:hover {
  color: #494949;
}
