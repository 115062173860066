.ARenderCourse {
  background: var(--white);
  margin: 20px 0;
  border-radius: 5px;
  padding: 10px 15px;
}
.ARenderCourse__header {
  cursor: pointer;
}
.ARenderModule__list {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;
}
.ARenderModule__item {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--gray);
  cursor: pointer;
}
.ARenderModule__item.active {
  border: 1px solid var(--green);
}
.ARenderLesson__item {
  border: 1px solid var(--gray);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.ARenderLesson__item:hover {
  border: 1px solid var(--green);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
}
.ARenderLesson__item .H4 i {
  color: var(--gray);
}
.ARenderLesson__item div {
  display: flex;
  align-items: center;
  gap: 5px;
  /* justify-content: space-between; */
}
.ListFilters {
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.ListFilters__item {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid var(--gray);
}
.ListFilters__item:hover {
  border: 1px solid var(--green);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.ListFilters__item.active {
  border: 1px solid var(--green);
  background: var(--green);
  width: fit-content;
}
