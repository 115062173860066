.SubmissionDoc h2 {
  font-size: 2.5rem;
}
.SubmissionDoc h3 {
  margin: 20px 0 5px;
  font-size: 1.6rem;
}
.SubmissionDoc p {
  color: var(--orange2);
  font-weight: 500;
  margin: 0;
}
.SubmissionDoc__modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
}
.SubmissionDoc__Mbtns {
  display: flex;
  gap: 20px;
}
.SubmissionDoc__doc {
  font-size: 1.4rem;
  margin: 10px 0;
}
.SubmissionDoc__doc a {
  color: var(--green);
}
.SubmissionDoc__doc i {
  margin-left: 3px;
}
