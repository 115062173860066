.AnchorIcon a {
  font-size: 1.6rem;
  text-align: start;
  padding: 10px;
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.AnchorIcon span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
