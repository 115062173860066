.InformationTeacher {
  background: var(--white);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  text-align: start;
  margin-bottom: 10px;
}
.InformationTeacher__header {
  display: flex;
  gap: 20px;
  align-items: center;
}
.InformationTeacher__header img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.InformationTeacher h2 {
  margin: 0;
  font-size: 2rem;
}
.InformationTeacher h3 {
  margin: 0;
  margin-top: 5px;
  font-size: 1.6rem;
  font-weight: normal;
  color: #838383;
}
.InformationTeacher__main {
  margin-top: 10px;
}
.InformationTeacher__main h2 {
  /* margin: 0; */
  /* margin-top: 5px; */
  font-size: 1.8rem;
  font-weight: 600;
  /* color: #838383; */
}
.InformationTeacher__main br {
  display: block;
  content: "";
  margin-top: 6px;
}
.InformationTeacher__main .InformationTeacher__span {
  color: var(--orange2);
  cursor: pointer;
}

.InformationTeacher__actions{
  display: flex;
  margin-top: 10px;
  gap: 1rem;
}