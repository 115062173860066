.tecnique_section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 950px;
  display: grid;
  place-items: center;

}

.main {
  padding: 10px;
  max-width: 95%;
  background-color: rgba(255, 255, 255, 0.781);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}


.main h1 {
  font-size: 70px;
  text-align: center;
}

.mainco h1 {
  font-size: 60px;
  text-align: center;
}

.main h3 {
  font-size: 35px;
  text-align: center;
}

.main_section {
  display: grid;
  grid-template-columns: repeat(2, 45%);
  background-image: url("https://res.cloudinary.com/pataformacgi/image/upload/c_scale,h_700/v1674760868/assets/FONDO_BLANCO_jhhzrw.png");
  background-size: cover;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 2rem;
}

.main_section .content-section {
  height: 700px;
  display: flex;
  align-items: center;
}

.main_section .content-section .content-description>span {
  font-size: 18px;
  font-weight: bold;
  color: var(--orange2);
}

.main_section .content-section .content-description>h3 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-top: 10px;
  /* color: var(--orange2); */
}

.main_section .content-section .content-description>p {
  font-size: 2rem;
  text-align: justify;
}

.main_section img {
  width: 100%;
}

@media screen and (max-width: 900px) {
  .main_section {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .main_section .content-section {
    height: auto;
    width: 90%;
  }

  .main_section .content-section .content-description {
    padding: 20px;
  }

  .main_section .content-section .content-description>h3 {
    font-size: 2.5rem;
  }

  .main_section .content-section .content-description>p {
    font-size: 1.5rem;
  }

  .img-main-section {
    max-width: 300px;
  }
}

.main_sectionobj {
  margin-top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url("https://res.cloudinary.com/pataformacgi/image/upload/c_scale,h_700/v1674760868/assets/FONDO_BLANCO_jhhzrw.png");
  height: 700pX;
  background-size: cover;
}

.main_sectionobj img {
  width: 400px;
  height: 300px;
  margin-bottom: 10px;
}

.item1 {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
}

.objetiv {
  width: 500px;
  height: 50px;
  color: white;
  display: flex;
  justify-content: end;
  align-items: center;
}

.objetiv span {
  font-size: 30px;
  font-weight: bold;
}

.item2 {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.objetivPublic {
  width: 500px;
  height: 50px;
  color: white;
  display: flex;
  justify-content: start;
  align-items: center;
}

.objetivPublic span {
  font-size: 30px;
  font-weight: bold;
}

.paObjetiv {
  font-size: 18px;
  color: white;
  margin-top: 15px;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 650px;
  padding-left: 20px;
  padding-bottom: 25px;
}

.paObjetivPublic {
  font-size: 18px;
  color: white;
  margin-top: 15px;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 650px;
  padding-left: 20px;
}

.main_characteristic {
  text-align: center;
  background-image: url("https://res.cloudinary.com/pataformacgi/image/upload/c_scale,h_700/v1674760868/assets/FONDO_BLANCO_jhhzrw.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.characteristic {
  display: grid;
  /* justify-content: space-evenly; */
  grid-template-columns: repeat(4, 270px);
  justify-content: center;
  padding: 10px;
  gap: 30px;
}

.characteristic div {
  /* width: 400px; */
  font-size: 18px;
  text-align: center;
}

.characteristic div p {
  /* background-color: aqua; */
  padding-top: 15px;
  padding-bottom: 15px;
  color: white;
}

.characteristic img {
  /* width: 150px; */
  height: 150pX;
  background-position: center;
  background-size: cover;
  margin-bottom: 20px;

}

.characterSpace {
  margin-top: 20%;
}

@media screen and (max-width: 1200px) {

  .characteristic {
    display: grid;
    /* justify-content: space-evenly; */
    grid-template-columns: repeat(2, 320px);
    justify-content: center;
    padding: 10px;
    gap: 30px;
  }

  .characterSpace {
    margin-top: 0;
  }

}

@media screen and (max-width: 730px) {

  .characteristic {
    display: grid;
    /* justify-content: space-evenly; */
    grid-template-columns: repeat(1, 330px);
    justify-content: center;
    padding: 10px;
    /* max-width: 320px; */
  }

  .characterSpace {
    margin-top: 0;
  }

}

.main_curricula {
  padding: 10px;
  display: flex;
  flex-direction: column;
  columns: 2;
  background-image: url("https://res.cloudinary.com/pataformacgi/image/upload/c_scale,h_700/v1674760868/assets/FONDO_BLANCO_jhhzrw.png");
  background-size: cover;
  text-align: center;
}

.main_curricula h2 {
  font-size: 50px;
  font-weight: bold;
  color: rgb(248, 112, 62);
}

.main_curricula button {
  font-size: 20px;
  font-weight: bold;
  /* background-color: white; */
  /* width: 450px; */
  height: 50px;
  margin-left: auto;
  margin-right: auto;
}

.main_curricula a {
  text-decoration: none;
  color: red;
}

.main_curricula .image-large {
  width: 1000px;
  height: 800px;
  margin: auto auto auto auto;
  margin-bottom: 20px;
}

.main_hora {
  display: flex;
  flex-direction: column;
  background-image: url("https://res.cloudinary.com/pataformacgi/image/upload/c_scale,h_700/v1674760868/assets/FONDO_BLANCO_jhhzrw.png");
  background-size: cover;
  text-align: center;
  height: 350px;
}

.main_hora h2 {
  font-size: 50px;
  font-weight: bold;
  color: rgb(248, 112, 62);
}

.imgHorario {
  /* height: 300px; */
  width: 650px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.imgBack {
  height: 800px;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.imgBack h2 {
  margin-right: auto;
}

.imgBack p {
  margin-top: 15%;
  margin-right: auto;
  margin-left: 20px;
  font-size: 30px;
  font-weight: bold;
}

.imgBack button {
  width: 250px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(248, 112, 62);
  color: white;
  border-radius: 50px;
}

.imgBack a {
  margin-left: auto;
}

.imgBack img {
  width: 700px;
  height: 100px;

}


@media screen and (max-width: 900px) {
  .NewHome__aboutUs {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (max-width: 700px) {
  .NewHome__head {
    min-height: auto;
  }

  .EducationVirtual {
    margin-top: 0;
  }

  .EducationVirtual h2 {
    text-align: center;
  }

  .NewHome__AText p {
    font-size: 1.6rem;
  }

  .NewHome__contactContain {
    flex-direction: column-reverse;
  }

  .NewHome__contactMap {
    min-width: auto;
    width: 100%;
  }

  .NewHome__content {
    flex-direction: column;
  }

  .CourseSection {
    min-height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .CardSection {
    width: 100%;
  }

  .CardSection__head {
    min-width: auto;
  }

  .NewHome__listSection {
    padding: 30px 10px;
    gap: 15px;
  }

  .NewHome__AImage {
    width: 90%;
  }
}

@media screen and (max-width: 900px) {
  .tecnique_section {
    height: 500px;
  }

  .main {
    /* width: 600px; */
    height: 250px;
  }

  .main h1 {
    font-size: 30px;
  }

  .main h3 {
    font-size: 20px;
  }

  .main_sectionobj img {
    width: 300px;
    height: 200px;
  }

  .objetiv {
    width: 250px;
    height: 30px;
  }

  .objetiv span {
    font-size: 20px;
  }

  .paObjetiv {
    font-size: 13px;
    color: white;
    width: 350px;
  }

  .objetivPublic {
    width: 250px;
    height: 30px;
  }

  .objetivPublic span {
    font-size: 20px;
  }

  .paObjetivPublic {
    font-size: 13px;
    color: white;
    width: 350px;
    padding-bottom: 10px;
  }

  .main_curricula h2 {
    font-size: 35px;
  }

  .main_curricula .image-large {
    width: 650px;
    height: 450px;
  }

  .main_hora h2 {
    font-size: 35px;
  }

}

@media screen and (max-width: 400px) {
  .tecnique_section {
    height: 400px;
  }

  .main {
    height: 150px;
  }

  .main h1 {
    font-size: 20px;
  }

  .main h3 {
    font-size: 10px;
  }

  .characteristic h2 {
    font-size: 16px;
  }

  .main_curricula h2 {
    font-size: 20px;
  }

  .main_curricula button {
    width: 250px;
  }

  .main_hora h2 {
    font-size: 25px;
  }

  .imgHorario {
    /* width: 310px; */
    height: 170px;
  }

  .imgBack img {
    width: 350px;
    height: 70px;
  }

  .imgBack p {
    font-size: 15px;
  }

  .imgBack button {
    width: 220px;
    height: 60px;
  }
}

/* //////////////// */

.list-items {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 5px;
  justify-content: center;
  /* padding: 20px; */
  background-image: url("https://res.cloudinary.com/pataformacgi/image/upload/c_scale,h_700/v1674760868/assets/FONDO_BLANCO_jhhzrw.png");
  background-size: cover;
}

.item {
  padding: 10px;
}

.item .img-item {
  padding: 10px;
}

.item .img-item img {
  height: 300px;
}

@media screen and (max-width: 900px) {
  .list-items {
    grid-template-columns: repeat(1, 80%);
  }
}

@media screen and (max-width: 600px) {
  .list-items {
    grid-template-columns: repeat(1, 100%);
  }

  .item .img-item img {
    height: 250px;
  }
}

.img-section {
  display: grid;
  grid-template-columns: repeat(1, auto);
  justify-content: center;
  margin-bottom: 20px;
}

.img-section .img-d {
  /* width: 300px; */
  display: none;
}

.img-d-block {
  width: 330px;
  /* display: flex; */
}


.list-image-display {
  display: grid;
  grid-template-columns: repeat(3, auto);
  /* background: red; */
  justify-content: center;
  gap: 20px;
  padding: 10px;
}

.list-image-display,
.img-section {
  display: none;
}


@media screen and (max-width: 1020px) {
  .image-large {
    display: none;
  }

  .list-image-display,
  .img-section {
    display: grid;
  }
}

.item .side {
  text-align: right;
}

.content-item {
  padding: 10px;
  border-radius: 20px;
}

.title-item {
  font-size: 25px;
  font-weight: bold;
}

.title-item span {
  font-size: 25px;
  font-weight: bold;
  background: var(--green);
  /* color: var(--green); */
  color: white;
  padding: 10px 7px 6px 7px;
  display: inline-block;
  border-radius: 5px 20px;
  margin-bottom: 10px;
}

.description-item {
  font-size: 16px;
  background: var(--green);
  padding: 5px 20px;
  color: white;
  border-radius: 5px 20px;
}

.side, .side span{
  border-radius: 20px 5px;
}

.description-item p {
  text-align: justify;
}

.page-target {
  border-radius: 10px;
  padding: 10px;
  background: var(--green);
  font-size: 16px;
}

.nav-display {
  width: 30px;
  padding: 20px 25px;
  font-weight: bold;
  font-size: 15px;
  border-radius: 50%;
  background: rgb(240, 240, 240);
  cursor: pointer;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .2);
  text-align: center;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.nav-d-one {
  border: 5px solid rgb(177, 0, 221);
  color: fuchsia;
}

.nav-d-two {
  border: 5px solid orange;
  color: orange;
}

.nav-d-three {
  border: 5px solid rgb(0, 201, 201);
  color: rgb(0, 201, 201);
}

.nav-d-one:active {
  background: rgba(0, 0, 0, .1);
}

.nav-d-two:active {
  background: rgba(0, 0, 0, .1);
}

.nav-d-three:active {
  background: rgba(0, 0, 0, .1);
}

.button-download {
  padding: 0px;
  margin: 10px;
  background: transparent;
}

.button-download:hover {
  background: transparent;
}

.button-download a {
  display: block;
  padding: 10px;
  color: red;
  font-size: 29px;
  margin: 10px;
  border-radius: 10px;
  background: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .2);
  padding: 10px;
  color: red;
  font-size: 14px;
}

.button-download a:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .4);
}

.title-section-body {
  font-size: 50px;
  font-weight: bold;
  color: rgb(248, 112, 62);
  margin: 25px 0px;
  padding: 5px;
}


@media screen and (max-width: 1200px) {
  .title-section-body {
    font-size: 35px;
  }
}

@media screen and (max-width: 600px) {
  .title-section-body {
    font-size: 25px;
  }

  /* .characteristic {
    flex-direction: column;
    align-items: center;

  } */

  .col-character {
    margin-top: 20px;
  }
}

.sub-content {
  background-image: url("https://res.cloudinary.com/pataformacgi/image/upload/v1676664827/assets/libros-voladores-caricaturas-apiladas_18591-47926_xtfn53.avif");
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  height: 400px;
  position: relative;
}

.sub-content .image-section {
  text-align: right;
}

.sub-content .image-section img {
  max-width: 100%;
}

.sub-content .section-text {
  text-align: center;
  padding: 10px;
}

.sub-content .image-side {
  position: absolute;
  bottom: 0;
  right: 0;
}

.sub-content p {
  font-size: 20px;
  font-weight: bold;
}

.button-redirect {
  display: inline-block;
  text-decoration: none;
  font-size: 29px;
  margin: 10px;
  border-radius: 50px;
  background: rgb(248, 112, 62);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .2);
  padding: 20px;
  color: white;
}

.button-redirect:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .4);
}


@media screen and (max-width: 900px) {
  .sub-content {
    background-size: 200px;
    height: 350px;
  }

  .sub-content .image-side img {
    width: 250px;
  }

  .sub-content p {
    font-size: 16px;

  }
}