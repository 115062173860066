.ContentVirtualClass {
  text-decoration: none;
}
.ContentVirtualClass__content {
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: var(--black);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ContentVirtualClass__content.available {
  background: var(--white);
}
.ContentVirtualClass__content.completed {
  background: #469585a1;
  color: var(--white);
}
.ContentVirtualClass__content:hover {
  box-shadow: 0px 0px 10px rgba(70, 149, 133, 0.5);
  transition: border 0.3s;
}
.ContentVirtualClass__content h3 {
  margin: 0;
  font-size: 1.6rem;
  text-align: end;
}
.ContentVirtualClass__content h2 {
  margin: 0;
  font-size: 2rem;
}
.ContentVirtualClass__content p {
  margin: 0;
  font-size: 1.6rem;
}
.ContentVirtualClass__modal {
  background: var(--white);
  padding: 20px;
  border-radius: 10px;
}
.ContentVirtualClass__modal h3 {
  text-align: end;
  font-size: 1.6rem;
  color: gray;
}
.ContentVirtualClass__modal h2 {
  text-align: center;
  font-size: 2rem;
}
