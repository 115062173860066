.Perfil {
  display: flex;
  align-items: center;
}
.Perfil__photo {
  width: 100%;
  min-width: 150px;
  background: var(--white);
  border-radius: 5px;
  margin: 5px 5px 50px 0;
}
.Perfil__Pimg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 25px auto 0;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
}
.Perfil__photoBtn {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
}
.Perfil__photoBtn i {
  background: var(--orange1);
  border-radius: 50%;
  box-shadow: 0 0 30px var(--orange1);
  padding: 10px;
  cursor: pointer;
}
.Perfil__information {
  width: 100%;
  min-width: calc(100% - 150px);
  border-radius: 5px;
}
@media screen and (max-width: 425px) {
  .Perfil {
    display: block;
  }
  .Perfil__photo {
    background: none;
    margin: 15px 0;
  }
}
