.searchContainer {
  background: var(--white);
  border-radius: 10px;
  height: 35px;
  padding: 0 5px 0 10px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
  border: 1px solid var(--green);
  font-size: 1.6rem;
  gap: 10px;
}
.searchContainer i {
  color: var(--black);
  filter: opacity(0.7);
}
.searchContainer a {
  text-decoration: none;
}
.searchContainer__input {
  border: none;
  background: none;
  outline: none;
  color: var(--color2);
  font-size: 1.6rem;
  font-weight: normal;
}
.searchContainer__input::placeholder {
  color: var(--color6);
  opacity: 0.7;
}
.Search__select {
  border: none;
  background-color: #469585a1;
  padding: 5px 8px;
  border-radius: 5px;
  outline: none;
  font-size: 1.4rem;
}
