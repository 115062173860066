.NormalLink {
  background: var(--orange2);
  font-size: 1.6rem;
  text-decoration: none;
  padding: 10px 15px;
  color: var(--white);
  border-radius: 10px;
}
.NormalLink:hover {
  opacity: 0.8;
}
