.ReportGraph_container{
    width: 600px;
    height: 600px;
}


.Reports__rangeDate .form-control.DatePicker .react-datepicker__input-container {
    width: auto;
}

@media screen and (max-width: 425px) {
    .ReportGraph_container{
        width: 100%;
        height: 100%;
    }
}