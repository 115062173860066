.ListCourses__list {
  border: 1px solid var(--gray);
  border-radius: 10px;
  padding: 5px;
  width: 100%;
}
.ListCourses__btn .DefaultBtn {
  border: 1px solid var(--green);
  background: var(--green);
  border-radius: 0;
  padding: 0 10px;
  border-radius: 5px;
}
.ListCourses__btn .DefaultBtn:hover {
  border: 1px solid var(--green);
}
