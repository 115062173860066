.PaymentInfo__payOnline {
  border: 2px solid var(--orange2);
  border-radius: 10px;
  background: #ee7c381a;
  font-size: 1.6rem;
  padding: 10px 10px 30px;
}
.PaymentInfo__payOnline a {
  color: var(--white);
  text-decoration: none;
  background: var(--orange2);
  padding: 5px 20px;
  border-radius: 5px;
}
.PaymentInfo__payOnline a:hover {
  color: var(--black);
}
.PaymentInfo__pay {
  border: 2px solid var(--yellow);
  border-radius: 10px;
  background: #f4b5181a;
  font-size: 1.6rem;
  padding: 10px;
}
.PaymentInfo__Ah4 {
  margin: 0;
}

.PaymentInfo__nro h4 {
  margin: 20px 0 5px;
}

.PaymentInfo__contact {
  border: 2px solid var(--green);
  border-radius: 10px;
  font-size: 1.6rem;
  padding: 10px;
  background: #4695851a;
  margin-bottom: 10px;
}
.PaymentInfo__contact p {
  margin: 0 0 10px;
  /* text-align: justify; */
  text-align: center;
}

.PaymentInfo__phone {
  margin: 5px 0;
}
