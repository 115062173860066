.GraduateSignUp {
  padding: 20px;
  background: var(--white);
  overflow: auto;
  border-radius: 15px;
}
.GraduateSignUp__title {
  font-size: 2.3rem;
  margin-top: 10px;
}
.GraduateSignUp__form h4 {
  font-size: 1.8rem;
  font-weight: normal;
  text-align: start;
  margin: 20px 0 10px;
}
.GraduateSignUp__form h5 {
  font-size: 1.4rem;
  font-weight: normal;
  text-align: start;
  margin: 10px 0;
}
.GraduateSignUp__Fname {
  display: flex;
}
.GraduateSignUp__Fci {
  display: flex;
}
.GraduateSignUp__Fonly {
  margin-right: 20px;
}
@media screen and (max-width: 576px) {
  .GraduateSignUp {
    padding-right: 30px;
    margin-bottom: 40px;
  }
  .GraduateSignUp__Fname {
    flex-direction: column;
  }
  .GraduateSignUp__Fci {
    flex-direction: column;
  }
  .GraduateSignUp__Fonly {
    margin: 0;
  }
}
