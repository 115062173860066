.LinkNav {
  width: 150px;
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  color: gray;
  font-size: 2rem;
}
.LinkNav:hover {
  color: var(--purple);
}
.nav__linkText {
  font-weight: 500;
  font-size: 1.6rem;
}
