.CardCategories__list {
  width: 100%;
  display: flex;
}
.CardCategories__card {
  font-size: 3rem;
  cursor: pointer;
  color: var(--white);
  width: calc((100% / 3));
  height: 100px;
  padding: 10px;
  text-decoration: none;
}
.CardCategories__card .H4 {
  text-transform: uppercase;
}
.CardCategories__card img {
  height: 50px;
}
@media only screen and (max-width: 700px) {
  .CardCategories__list {
    flex-direction: column;
    height: 300px;
  }
  .CardCategories__card {
    width: 100%;
    height: 300px;
  }
}
