@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
body {
  font-family: 'Spartan', sans-serif;
  margin: 0;
}
button {
  font-family: 'Spartan', sans-serif;
  border: none;
}
.header__logo {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media screen and (max-width: 768px) {
  .header__logo img:last-child {
    display: none;
  }
}
