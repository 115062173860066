.CourseModule {
  border: 2px solid var(--white);
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 15px;
  background: var(--white);
  color: #494949;
}
.CourseModule__unitBar {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CourseModule__progressCircles {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;
}
.CourseModule__progressBar {
  position: absolute;
  border: 2px gray solid;
  border-radius: 50px;
  margin: 0 5px;
  width: 98%;
}
.CourseModule__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CourseModule__link {
  text-decoration: none;
  margin-left: 15px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px 5px;
  background: var(--orange2);
  color: var(--white);
  font-size: 1.6rem;
}
.CourseModule__details {
  text-align: center;
  font-size: 1.4rem;
  background: #4695851a;
  padding: 8px;
  border-radius: 5px;
  color: var(--green);
  cursor: pointer;
}
.CourseModule__cicleText {
  display: flex;
  align-items: center;
  gap: 10px;
}
.CourseModule__Text {
  cursor: pointer;
}
.CourseModule__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
}
.CourseModule__version {
  font-weight: 500;
  font-size: 1.6rem;
  border: 1px solid gray;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: -5px;
}
/*Descripcion del docente*/
.divDescription{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}