.btnExternal {
  margin: 20px 0;
  width: 80%;
  height: 35px;
  background: #6A308180 0% 0% no-repeat padding-box;
border-radius: 10px;
  font-size: 1.5rem;
  border: none;
  box-shadow: 0px 0px 5px rgba(1, 0, 0, 0.16);
  color: #FFFFFF;
  text-align: start;
  padding: 0 15px;
  cursor: pointer;
}
.btnExternal i {
  float: right;
  margin-left: 10px;
}
@media screen and (max-width: 425px) {
  .btnExternal {
    width: 90%;
  }
}
