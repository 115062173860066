.StyledDropzone i {
  font-size: 4rem;
  color: var(--green);
  margin-bottom: 5px;
}
.StyledDropzone h4 {
  font-weight: 500;
  margin: 0;
  font-size: 1.5rem;
}
.StyledDropzone p {
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
}
.StyledDropzone .StyledDropzone__h3--gray {
  color: #5d5d5d;
}
.StyledDropzone .StyledDropzone__h3--orange {
  color: var(--orange2);
}
.StyledDropzone__fields .StyledDropzone__Fh4 {
  margin-top: 10px;
}
.StyledDropzone__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.StyledDropzone__container .NormalButton {
  padding: 5px 2px;
  width: 50%;
  margin: 0;
  font-size: 1.6rem;
  border-radius: 5px;
}
.StyledDropzone__container .StyledDropzone__Fh3 {
  font-size: 1.6rem;
  margin: 0 0 5px;
}
.StyledDropzone__file {
  background: var(--white2);
  padding: 5px;
  display: flex;
  width: 97%;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 5px;
}
.StyledDropzone__file div {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: #469585a1;
  color: var(--white);
  line-height: 40px;
  border-radius: 5px;
  font-size: 1.4rem;
}
.StyledDropzone__btn {
  margin-top: 10px;
  width: 100%;
  text-align: end;
}
.StyledDropzone__file h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.StyledDropzone__file .correct {
  color: var(--green);
}
.StyledDropzone__file .wrong {
  color: #c93f3f;
}
.StyledDropzone__file i {
  font-size: 2rem;
  color: #c93f3f;
  background: #c93f3f3f;
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  border-radius: 5px;
  cursor: pointer;
}
.StyledDropzone__file i:hover {
  background: #c93f3f1c;
}
.StyledDropzone label {
  margin: -10px 0 -15px;
  font-size: 1.4rem;
}
