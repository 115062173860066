.LinkCard {
  text-decoration: none;
  color: var(--black);
  display: flex;
  text-align: start;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin: 15px 0;
  gap: 5px;
}
.LinkCard:hover {
  color: var(--orange2);
}
.LinkCard h4 {
  font-size: 1.6rem;
  margin: 0;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.LinkCard i {
  font-size: 1.6rem;
}
