.LogoCapacita {
  position: relative;
}
.LogoCapacita .LogoCapacita__main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 75px;
  padding: 20px
}
.LogoCapacita .LogoCapacita__fondo {
  height: 110px;
  animation: 3.5s rotate360 infinite linear;
}
@keyframes rotate360 {
  to {
    transform: rotate(360deg);
  }
}
