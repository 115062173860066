.SubCategory {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--gray);
  padding: 0 0 0 5px;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
}
