.Enrollment {
  margin: 15px 3%;
  text-align: center;
}
.Enrollment h3 {
  font-size: 2rem;
}
.Enrollment p {
  font-size: 1.4rem;
}
