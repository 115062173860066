.Home {
  color: var(--black);
  font-family: 'Spartan', sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Message {
  position: fixed;
  top: 200px;
  z-index: 2;
  right: 15px;
  background: var(--white);
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.Message__container {
  display: block;
  position: relative;
}

.Message__close {
  z-index: 3;
  right: 7px;
  top: 7px;
  position: absolute;
  font-size: 2.5rem;
  color: var(--purple);
}

.Message img {
  border-radius: 5px;
  box-shadow: 0 0 5px #19376579;
  height: 120px;
  padding: 10px;
}

.Home__nav {
  display: flex;
  gap: 10px;
  margin-left: 10px;
}

.Home__nav .Home__option {
  color: gray;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.8rem;
  border: 1px solid gray;
  padding: 8px 10px 5px;
  border-radius: 10px;
}

.Home__nav .Home__option:hover {
  color: var(--green);
  border: 1px solid var(--green);
}

.Home__nav .Home__optionAc {
  color: var(--green);
  font-weight: 600;
}

.Home__head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Home__header {
  position: relative;
  text-align: center;
  height: calc(100vh - 250px);
}

.Home__header .reactPlayerC video {
  background: white;
}

.Home_OurCourses {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('https://res.cloudinary.com/pataformacgi/image/upload/v1675187785/assets/CGI_pagina_hr8a3m.png');
  background-size: cover;
}

.EducationVirtual {
  position: absolute;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
}

.link {
  z-index: 1;
  background-color: #f26d3d;
  text-decoration: none;
  border-radius: 25px;
  font-size: 20px;
  color: white;
  padding: 15px 25px;
}

.link-success {
  z-index: 1;
  background: linear-gradient(100deg, var(--purple), rgba(92, 214, 189, 1));
  text-decoration: none;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  padding: 15px 25px;
  transition: all .1s ease;
}

.link:hover {
  opacity: 0.8;
}

.link-success:hover {
  opacity: 0.9;
  transform: scale(1.02);
}

.link-success:active {
  opacity: 1;
  transform: scale(1);
}

.Home_OurCourses>div>img {
  min-width: 167px;
  width: 40%;
}

.Home_OurCourses>div>h2>span:nth-child(2) {
  color: #f26d3d;
  font-size: 40px;
  font-weight: 900;
  font-style: oblique;
  text-shadow: 0px 0px 25px rgb(255, 255, 255);
}

.Home_OurCourses>div>h2 {
  font-size: 40px;
  font-weight: 900;
  text-shadow: 0px 0px 25px rgb(255, 255, 255);
}

.Home__Hmain {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
}

.Home__content {
  text-align: center;
  font-size: 2rem;
  color: var(--black);
  height: auto;
}

.Home__opinion {
  text-align: center;
  margin: 30px 0;
}

.Home__Ocards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5%;
}

/*Footer*/
.Home__footer {
  color: var(--white);
  background: #469585;
  display: flex;
  text-align: center;
  justify-content: space-around;
  padding: 30px 30px 0;
  font-size: 1.5rem;
}

.Home__Fdirection {
  width: 200px;
}

.Home__Ficons i {
  color: white;
  font-size: 3rem;
  margin-right: 20px;
  cursor: pointer;
}

.Home__Ficons i:hover {
  opacity: 0.6;
  transform: scale(1.3);
  transition: transform 0.3s;
}

.Home__Line {
  border: 1px solid #469585;
  height: 100px;
}

.CoursesTop {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CoursesTop__link {
  margin: 50px 0 20px;
  position: relative;
}

.CoursesTop__link .CoursesTop__figure {
  position: absolute;
  top: -30px;
  right: -150px;
  background-image: url(../../assets/figures/figure1.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 60px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CoursesTop__figure span {
  color: var(--white);
  font-weight: 600;
  font-size: 1.6rem;
}

.CoursesTop__figure span:last-child {
  align-self: start;
  margin-left: 25px;
}

.Home__Dslider {
  overflow: hidden;
}

.Home__Dslider ul {
  width: 200%;
  display: flex;
  padding: 0;
  animation: cambio 5s infinite alternate;
}

.Home__Dslider li {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home__Dslider li img {
  width: 900px;
}

.Home__Image {
  text-align: center;
}

.Home__Image img {
  width: 700px;
}

@keyframes cambio {
  0% {
    margin-left: 0%;
  }

  35% {
    margin-left: 0%;
  }

  65% {
    margin-left: -100%;
  }

  100% {
    margin-left: -100%;
  }
}

@media screen and (max-width: 900px) {
  .Home__Himg {
    width: 400px;
  }

  .Home__header {
    height: 450px;
    margin-bottom: 0px;
  }

  .CoursesTop__link {
    width: 90%;
  }

  .CoursesTop__link .CoursesTop__banner {
    width: 70%;
  }

  .CoursesTop__link .CoursesTop__figure {
    right: 0;
  }

  .Home__footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Home__Ocards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Home__Line {
    display: none;
  }

  .Home__Dslider li img {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .Home__content {
    height: auto;
  }

  .Home__header .reactPlayerC {
    display: none;
  }

  .Home__header {
    background-image: url('https://res.cloudinary.com/dzxmzxvbr/image/upload/v1607986654/diplomados/homePageDinamic/3_bnchji.png');
    background-position: center;
    background-size: cover;
  }

  .Home__Hmain {
    background: rgba(255, 255, 255, 0.226);
  }

  .EducationVirtual {
    top: 0;
    margin-top: 150px;
  }

  .Home__Image img {
    width: 80%;
  }
}

@media screen and (max-width: 425px) {
  .Home__Himg {
    width: 300px;
  }

  .CoursesTop__link {
    width: 100%;
  }

  .CoursesTop__link .CoursesTop__banner {
    width: 100%;
  }

  .CoursesTop__link .CoursesTop__figure {
    top: -50px;
  }

  .EducationVirtual {
    top: 0;
    margin-top: 150px;
  }
}

.link-empty {
  z-index: 1;
  background-color: transparent;
  text-decoration: none;
  border-radius: 25px;
  font-size: 20px;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px 25px;
}