.StatementMain__editorJs {
  text-align: start;
}
.FormRelease__delete {
  font-size: 1.6rem;
  color: #c93f3f;
  background: #c93f3f3f;
  cursor: pointer;
  outline: none;
  border: 1px solid var(--white);
  border-radius: 5px;
  padding: 5px 10px;
}
.FormRelease__delete:hover {
  background: #c93f3f1c;
}
.ReleaseList__ad {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
.ReleaseList__ad h3 {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 600;
}
