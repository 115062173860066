.CardCertificate {
  display: flex;
  border-radius: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid transparent;
  transition: all 0.4s ease;
}
.CardCertificate:hover {
  border: 1px solid var(--green);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.CardCertificate__text {
  padding: 10px;
  width: 100%;
  cursor: pointer;
}
.CardCertificate .CardCertificate__btnDetail {
  color: var(--green);
  height: 100%;
  display: flex;
  padding: 0 15px;
  align-items: center;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  text-decoration: none;
}
.CardCertificate .CardCertificate__btnDetail:hover {
  background: #458e7f34;
}
.CardCertificate__btns {
  min-width: fit-content;
}
.CardCertificate__btns .CardCertificate__anchor {
  background: var(--green);
  color: var(--white);
  border-radius: 10px;
  padding: 8px 10px 5px;
  font-size: 1.5rem;
}
@media screen and (max-width: 500px) {
  .CardCertificate {
    flex-direction: column;
  }
  .CardCertificate .CardCertificate__btnDetail {
    display: none;
  }
  .CardCertificate__btns {
    display: none;
    margin-bottom: 15px;
  }
}
