.UsersProgram__btn {
  display: flex;
  justify-content: flex-end;
}

.card-mini{
  width: 100%;
  flex-wrap: nowrap;
  background-color: rgb(230, 230, 230);
  border-radius: 5px;
  padding: 5px;
}

.label-text-error{
 /* display: block; */
 font-size: 12px;
 color:red;
 grid-column: 3 / 3;
}

.card-form {
  display: grid;
  grid-template-columns: auto 40px 40px;
}

.card-form .card-form-title{
  text-align: right;
  font-size: 15px;
  color:gray;
  padding: 5px;
  font-weight: bold;
  /* display: block; */
  /* grid-column: 1 / 3; */
  /* background-color: red; */
}

.card-form .text-field{
  /* display: block; */
  outline: none;
  /* padding: 3px; */
  border: 1px solid white;
  /* grid-column: 1 / 10; */
  font-weight: bold;
}

.card-form .input-button-icon{
  display: block;
  color: var(--green);
  font-size: 25px;
  /* grid-column: 2 / 10; */
}
