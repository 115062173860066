.ActionButton {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.ActionButton {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
@media screen and (max-width: 425px) {
  .ActionButton {
    flex-direction: column-reverse;
    gap: 5px;
  }
}
