.ModulesRolUser__option {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  gap: 5px;
}
.PercentageGrades hr {
  border: 1px solid var(--green);
}
.PercentageGrades__lesson {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PercentageGrades__lesson h4 {
  width: calc(100% - 100px);
}
