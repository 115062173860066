.ListExtensionsLesson__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--green);
  margin-bottom: 5px;
  padding: 5px;
  text-align: start;
  border-radius: 5px;
}
.ListExtensionsLesson__ico {
  font-size: 2rem;
  color: var(--green);
}
.FormEditUser {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.FormEditUser__user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.FormEditUser__text {
  font-size: 1.6rem;
  border: 1px solid var(--green);
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  text-align: start;
}
.FormEditUser__user i {
  font-size: 1.6rem;
  color: #c93f3f;
  background: #c93f3f3f;
  cursor: pointer;
  outline: none;
  border: 1px solid var(--white);
  border-radius: 5px;
  padding: 5px 10px;
}
.FormEditUser__user i:hover {
  background: #c93f3f1c;
}
