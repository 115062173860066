.itemInformation {
  text-align: center;
  border-radius: 20px;
  background: #f8f8f8;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16); */
  overflow-y: scroll;
  margin-right: 5px;
  height: auto;
  scrollbar-color: var(--green) transparent;
  scrollbar-width: thin;
  padding: 15px 5% 10px calc(5% - 5px);
}
.itemInformation::-webkit-scrollbar {
  width: 1rem;
}
.itemInformation::-webkit-scrollbar-track {
  background: transparent;
  margin: 20px 0;
}
.itemInformation::-webkit-scrollbar-thumb {
  background: var(--green);
  border-radius: 25px;
}
