.EditorInput {
  text-align: start;
}
.EditorInput label {
  font-size: 1.2rem;
  color: gray;
}
.EditorInput__editor {
  margin-top: 5px;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 5px 15px;
  font-size: 1.4rem;
}
