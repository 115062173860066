.LineBar {
  width: 100%;
  border-radius: 50px;
  background: var(--white2);
  padding: 0 3px;
  display: flex;
  align-items: center;
  border: var(--green) solid 1px;
}
.LineBar__progress {
  border-radius: 50px;
  background: var(--green);
  height: 5px;
}
