.ConceptRelation__main {
  display: flex;
  gap: 10px;
}
.ConceptRelation__List {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 12px;
}
.ConceptRelation__Drag {
  width: 50%;
}
.ConceptRelation__question {
  border: 1px solid gray;
  padding: 10px 15px 5px;
  font-size: 1.8rem;
  text-align: start;
  border-radius: 5px;
  background: var(--white);
  display: flex;
  gap: 10px;
  align-items: center;
  height: 100px;
}
.ConceptRelation__question img {
  height: 100px;
  border-radius: 5px;
}
.ConceptRelation__drag .DragList__Quote {
  height: 100px;
  font-size: 1.8rem;
}
@media screen and (max-width: 768px) {
  .ConceptRelation__main {
    flex-direction: column;
  }
  .ConceptRelation__List {
    width: auto;
  }
  .ConceptRelation__Drag {
    width: auto;
  }
}
