.ProgressBar h2 {
  font-size: 2rem;
  margin-top: 0;
}
.ProgressBar__step {
  display: flex;
  align-items: center;
  gap: 20px;
  width: fit-content;
  cursor: pointer;
}
.ProgressBar__head {
  text-align: start;
}
.ProgressBar__head h3 {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0;
}
.ProgressBar__head h4 {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  color: #5f5f5f;
}
