/* .Modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.Modal .Modal__container {
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  padding: 15;
  height: 100vh;
  margin: 0 auto;
  width: 100vw;
}
.Modal__close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  border: none;
  cursor: pointer;
}
@media (min-width: 900px) {
  .Modal .Modal__container {
    top: 2rem;
    width: 900px;
    height: 500px;
  }
} */

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000061;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.Modal__container {
  position: relative;
  background-color: var(--white);
  height: 100%;
}

.Modal__close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  border: none;
}
@media (min-width: 900px) {
  .Modal__container {
    top: 2rem;
    width: 900px;
    height: 500px;
  }
}
