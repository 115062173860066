.Payment__list {
  display: flex;
  gap: 20px 10px;
  flex-wrap: wrap;
  justify-content: center;
  /* justify-content: space-evenly; */
  
}
.CardPayment {
  gap: 15px;
  width: 280px;
  padding: 20px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  border: 2px solid #cab6b7;
  /* justify-content: space-between; */
}
.CardPayment .DefaultBtn {
  padding: 10px 25px;
  background: var(--purple);
  border: 2px solid var(--purple);
}
.CardPayment.free .DefaultBtn:disabled {
  background: var(--white);
  color: var(--purple);
  opacity: 0.5 !important;
}
.CardPayment.monthly {
  border: 5px solid var(--green);
}
.CardPayment.yearly {
  border: 2px solid var(--green);
}
