.CoursesTeacher {
  padding: 10px;
}
.CoursesTeacher__header {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
}
.CoursesTeacher__header h2 {
  font-size: 2rem;
  margin: 0;
}
.ListStudents {
  background: var(--white);
  padding: 15px;
  border-radius: 10px;
}
.ListStudents__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.ListStudents__header .Loading {
  margin-top: -25px;
}
.CoursesTeacher__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
