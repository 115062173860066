.GraduatesUTB {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 25px;
  background: #eff0f3;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 10px;
}
.NavCatalog {
  padding: 10px;
  background: #d177421a;
  border-radius: 5px;
  width: 150px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NavCatalog.activate {
  background: #d1774291;
  color: var(--white);
}
.NavCatalog img {
  height: 50px;
}
@media screen and (max-width: 1024px) {
  .GraduatesUTB {
    grid-template-columns: 1fr;
  }
}
