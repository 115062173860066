.Institution__subtitle {
  font-size: 2.5rem;
  text-align: start;
  margin-bottom: 5px;
  font-weight: 600;
}
.Institution h1 {
  font-size: 3rem;
}
.Institution__information h4 {
  font-size: 2rem;
  text-align: start;
  margin: 0;
  margin-bottom: 5px;
}
.Institution__information p {
  font-size: 1.6rem;
  margin: 0;
  text-align: justify;
}
.Institution__information {
  text-align: end;
}
