.Account {
  margin: 20px 5% 30px;
  font-size: 1.6rem;
}
.Account__titleSpn {
  font-weight: bold;
  font-size: 1.6em;
}
.Account__data {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  padding: 15px 0;
  background: var(--white);
}
.Account__span {
  font-size: 2rem;
  font-weight: normal;
  margin-top: 25px;
  margin-bottom: 10px;
}
.Account__perfil {
  margin: 0 20px;
}
.Account__info {
  margin: 10px 20px;
}
