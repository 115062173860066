.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  background-color: var(--white);
  height: 70px;
}
.Header img {
  height: 60px;
}
.HeaderBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, rgb(118, 34, 150) 0%, #5cd6bd 100%);
  padding: 10px 20px 5px;
  margin-bottom: 5px;
}
.HeaderBanner__main {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 20px;
}
.HeaderBanner .NormalLink {
  color: var(--purple);
  font-weight: 700;
  border-radius: 5px;
  animation: wiggle 2s linear infinite;
}
.Header__option {
  display: flex;
  align-items: center;
  gap: 20px;
}
.Header__list {
  display: flex;
  gap: 20px;
  list-style-type: none;
}
.Header__list a {
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 800;
  color: var(--purple);
}
@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}
@media screen and (max-width: 900px) {
  .HeaderBanner {
    flex-direction: column;
    gap: 10px;
  }
  .HeaderBanner__text .H3 {
    text-align: center !important;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 900px) {
  .Header__list li {
    display: none;
  }
  .Header__list li:first-child {
    display: block;
  }
}
@media screen and (max-width: 425px) {
  .Header img {
    height: 40px;
  }
  .HeaderBanner__main > .H3 {
    font-size: 3rem !important;
  }
  .HeaderBanner .NormalLink {
    display: none;
  }
}
