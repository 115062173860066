.Release {
  background: var(--white);
  padding: 20px;
  border-radius: 10px;
  /* width: 50%; */
  /* margin: 0 auto; */
}
.Release__title {
  text-align: center;
  text-decoration: underline;
  font-size: 1.8rem;
  color: var(--green);
}
.Release__text {
  margin: 0;
  padding: 0;
}
.Release__text li {
  font-size: 1.6rem;
  margin: 15px 0;
  list-style: none;
  font-weight: 500;
}
.Release__text li span {
  color: var(--orange2);
}
.Release__text--right {
  font-size: 1.5rem;
  text-align: end;
  color: var(--green);
}
.Release__btn {
  margin-top: 10px;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .Release {
    width: 90%;
  }
}
