.About__title {
  background-color: var(--purple);
  padding: 90px 0 15px;
}
.About__title .H2 {
  text-transform: uppercase;
}
.About__main {
  display: flex;
}
.About__lines {
  padding: 5px 5px 0 0;
  border-right: 8px solid var(--purple);
}
.About__lines div {
  height: calc((100% / 4) - 5px);
  width: 30px;
  background-color: var(--purple);
  margin-bottom: 5px;
}
.About__content {
  padding: 20px 30px;
}
.About__flex {
  display: flex;
  gap: 20px;
  margin-right: 20px;
  align-items: center;
}
.About__flex div {
  width: 100%;
}
.About__flex img {
  width: 80px;
}
.About__hr {
  height: 5px;
  background-color: var(--purple);
}
.About__cards {
  display: flex;
  gap: 20px;
}
.About__cards .About__head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}
.About__head img {
  width: 60px;
}
.About__head .H2 {
  text-transform: uppercase;
}
.About__cards hr {
  border: 2px solid var(--purple);
}
.About__cards .About__card {
  width: calc(80% / 3);
}
.About__card ul {
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  list-style-type: none;
  padding: 0;
}
.About__card ul li {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .About__cards {
    flex-direction: column;
  }
  .About__cards .About__card {
    width: auto;
  }
  .About__cards hr {
    width: 80%;
    border: 1px solid var(--purple);
  }
}
@media screen and (max-width: 425px) {
  .About__lines {
    display: none;
  }
  .About__content {
    padding: 10px;
  }
}
