.snackText {
  min-width: 170px;
  margin-left: -140px;
  text-align: center;
  padding: 16px;
  position: fixed;
  z-index: 2;
  left: 50%;
  bottom: 30px;
  font-size: 1.6rem;
  border-radius: 10px;
}
.snackText {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.snackText i {
  margin-right: 10px;
}
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
