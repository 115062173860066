.VideoAside__btn i {
  display: none;
}
.VideoAside__btn button {
  width: 95% !important;
}
@media screen and (max-width: 1024px) {
  .VideoAside__btn span {
    display: none;
  }
  .VideoAside__btn i {
    display: block;
    font-size: 2rem;
  }
  .VideoAside__btn button {
    width: 100% !important;
  }
  .VideoAside__btn .ButtonNavigation {
    flex-direction: row;
  }
}
