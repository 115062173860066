.QuestionForm {
  width: 900px;
  margin: 10px auto;
  background: #f8f8f8;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid var(--green);
}
.QuestionForm__response {
  display: flex;
  align-items: center;
  gap: 10px;
}
.QuestionForm__inquiry {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.QuestionForm__response button {
  font-size: 1.6rem;
  color: #c93f3f;
  background: #c93f3f3f;
  width: 35px;
  height: 35px;
  min-width: 35px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}
.QuestionForm__response .DefaultBtn {
  width: max-content;
  padding: 20px;
  color: var(--white);
  background: var(--orange2);
}
.QuestionForm__response .DefaultBtn:hover {
  opacity: 0.8;
}
.QuestionForm__response button:hover {
  background: #c93f3f1c;
}
.QuestionForm__response .DefaultBtn:hover {
  opacity: 1;
  background: var(--orange2);
  border: none;
  color: var(--black);
}
.QuestionForm__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.RenderImage {
  width: 100%;
}
.RenderImage img {
  height: 120px;
  background-size: cover;
  border-radius: 10px;
}
@media screen and (max-width: 900px) {
  .QuestionForm {
    width: 90%;
    margin: 10px auto;
    padding: 10px;
  }
  .QuestionForm__btns {
    flex-direction: column;
  }
}
