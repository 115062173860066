.ProgramAside__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ProgramAside__link {
  width: 100%;
  border: 1px solid var(--green);
  padding: 10px;
  border-radius: 5px;
  color: var(--black);
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
.ProgramAside__link span {
  text-align: start;
}
.ProgramAside__link i {
  font-size: 2rem;
  color: var(--orange2);
}
.ProgramAside__link:hover {
  color: var(--white);
  background: var(--green);
}
.ProgramAside__link:hover i {
  color: var(--black);
}
.PlanList__item {
  border: 1px solid var(--gray);
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 5px;
}
.PlanList__item.isMain {
  border: 1px solid var(--green);
  background: #458e7f1a;
}

.PlanList__item .PlanList__payments {
  cursor: pointer;
}
