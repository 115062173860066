.CheckboxGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: start;
}
.CheckboxGroup__input input {
  border: none;
  display: none;
}
.CheckboxGroup__input label {
  display: inline-block;
  padding: 10px;
  border: 1px solid gray;
  width: 98%;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.6rem;
}
.CheckboxGroup__input label img {
  height: 120px;
  border-radius: 5px;
}
.CheckboxGroup__input label input {
  position: absolute;
  display: none;
}

.CheckboxGroup__input label:hover {
  color: var(--green);
  border: 1px solid var(--green);
}

.CheckboxGroup__input input[type='checkbox']:checked + label {
  background: var(--green);
  color: var(--white);
  border: 1px solid var(--green);
}

@media screen and (max-width: 768px) {
  .CheckboxGroup__input label {
    width: 96%;
  }
}
