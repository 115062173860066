.Login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form__span {
  color: #000;
  font-weight: bolder;
  filter: grayscale(20%) opacity(1);
  font-size: 1.6rem;
  text-shadow: 0 0 28px var(--color5);
}
.forgotPassword {
  width: 100%;
  color: gray;
  font-size: 1.4rem;
}
.forgotPassword a {
  color: var(--orange2);
  text-decoration: none;
}
.Change_text {
  font-size: 1.4rem;
}
