.CardAttendance {
  width: 200px;
}
.CardAttendance__link {
  padding: 10px;
  border: 1px solid var(--white);
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.CardAttendance__link:hover {
  border: 1px solid var(--green);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.CardAttendance__img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid gray;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
}
.CardAttendance__btn {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
  color: var(--white);
  background: gray;
  margin: 0 auto;
  transform: translateY(-10px);
  cursor: pointer;
}
.CardAttendance__btn:hover {
  opacity: 0.8;
}
