.SeminarUAGRM__section {
  display: flex;
  gap: 15px;
}
.SeminarUAGRM__info {
  width: 60%;
}
.SeminarUAGRM__images {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.SeminarUAGRM__header {
  background-image: url('https://res.cloudinary.com/dsgaf5zoj/image/upload/v1625231844/assets/hacluxvtmwfe27zx7hch.png');
  padding: 20px 0;
  padding: 15px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.SeminarUAGRM__content {
  padding: 15px;
  border-radius: 10px;
  background: var(--white2);
}
.SeminarUAGRM__content i {
  font-size: 7rem;
  color: #193765;
}
.SeminarUAGRM__form {
  padding: 15px 20px 15px 15px;
  border-radius: 10px;
  background: var(--white2);
  width: 40%;
}
.SeminarUAGRM__form label {
  font-size: 1.6rem;
}
.SeminarUAGRM__form .DefaultBtn {
  background-color: #bf1717;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid#bf1717;
  padding: 10px 10px 5px;
  text-align: center;
  margin: auto;
  width: 102%;
}
.Timekeeper {
  display: flex;
  gap: 20px;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  width: fit-content;
  align-items: center;
}
.Timekeeper > div {
  display: flex;
  padding: 10px 5px;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
  background: #193765;
  border-radius: 5px;
  font-size: 2.5rem;
  color: var(--white);
  font-weight: 700;
}
.Timekeeper__item {
  width: 80px;
  color: var(--white);
}
.Timekeeper__item h4 {
  margin: 0;
}
.SeminarUAGRM__topics {
  background: var(--white2);
  padding: 15px 25px;
  border-radius: 10px;
}
.SeminarUAGRM__title {
  display: flex;
  align-items: center;
  gap: 10px;
}
.SeminarUAGRM__title div {
  background: #193765;
  height: 8px;
  width: 100%;
  margin: 0 30px 10px;
}
.SeminarUAGRM__teachers {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
}
.SeminarUAGRM__list {
  margin: 0 auto;
  width: fit-content;
}
.SeminarUAGRM__list li {
  margin: 10px 0;
  font-size: 2rem;
  font-weight: 500;
  color: var(--gray);
}
.CardTeachers {
  padding: 0 30px;
  border-radius: 10px;
  background: #1937651a;
  text-align: center;
  width: calc(100% / 3);
  margin-top: 70px;
}
.CardTeachers__translate {
  transform: translateY(-60px);
}
.CardTeachers img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}
.CardTeachers .H4 {
  text-transform: uppercase;
}
@media screen and (max-width: 1024px) {
  .SeminarUAGRM__section {
    flex-direction: column;
  }
  .SeminarUAGRM__section > div {
    width: auto;
  }
  .SeminarUAGRM__teachers {
    flex-direction: column;
  }
  .SeminarUAGRM__teachers > div {
    width: auto;
  }
  .Timekeeper {
    flex-wrap: wrap;
    gap: 5px;
  }
}
@media screen and (max-width: 430px) {
  .Timekeeper {
    width: auto;
  }
  .Timekeeper > div {
    padding: 5px 0;
    width: 100%;
    gap: 0;
    font-size: 1.6rem;
    align-items: center;
  }
  .Timekeeper .Timekeeper__item {
    width: auto;
    padding: 10px;
  }
  .SeminarUAGRM__topics {
    padding: 10px;
  }
  .SeminarUAGRM__title {
    flex-wrap: wrap;
  }
  .SeminarUAGRM__title > div {
    display: none;
  }
  .SeminarUAGRM__title img {
    width: auto;
  }
}
