.ContentHomework__link {
  text-decoration: none;
}
.ContentHomework {
  background: var(--white);
  border-radius: 10px;
  padding: 10px 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: var(--black);
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.ContentHomework:hover {
  box-shadow: 0px 0px 10px rgba(70, 149, 133, 0.5);
  transition: border 0.3s;
}
.ContentHomework .ContentHomework__date {
  margin: 0;
  font-size: 1.6rem;
  text-align: end;
}
.ContentHomework .ContentHomework__name {
  margin: 0 0 5px;
  font-size: 1.6rem;
}
.ContentHomework__main {
  font-weight: 400;
  font-size: 1.6rem;
}
/* .ContentHomework__head .H4 {
  width: ;
} */
.ContentHomework__head {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
