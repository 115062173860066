.TabbedNavigation__header {
  width: fit-content;
  display: flex;
  gap: 8px;
  margin: 5px 0 0 10px;
  padding: 0 10px;
}
.TabbedNavigation__tab {
  background-color: var(--white2);
  padding: 8px 25px;
  border-radius: 8px 8px 0 0;
  box-shadow: 1px -3px 8px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  color: var(--gray);
}
.TabbedNavigation__tab .H3 i {
  display: none;
}
.TabbedNavigation__tab.activate {
  background-color: var(--white);
  color: var(--orange2);
}
.TabbedNavigation__content {
  box-shadow: 1px -3px 6px rgba(0, 0, 0, 0.05);
  min-height: 1px;
  padding: 10px 20px;
  background: var(--white);
}
@media screen and (max-width: 425px) {
  .TabbedNavigation__tab .H3 i {
    display: block;
  }
  .TabbedNavigation__tab .H3 span {
    display: none;
  }
}
