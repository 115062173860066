.InputSelect {
  display: flex;
  justify-content: space-between;
  border: solid 1px rgba(0, 0, 0, 0.2);
  margin: 8px 0;
  border-radius: 10px;
  padding: 10px;
  color: var(--color3);
  font-size: 1.6rem;
}
.InputSelect select {
  outline: 0;
  background: var(--color8);
  border: none;
  cursor: pointer;
  color: var(--color6);
  font-size: 1.6rem;
  border-radius: 10px;
}
