.Programs {
  background: #eff0f3;
  border-radius: 10px;
  padding: 15px;
}
.Programs__list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
.CardCourses {
  width: 350px;
  border: 1px solid var(--green);
  border-radius: 10px;
  background: var(--white);
}
.CardCourses a {
  text-decoration: none !important;
}
.CardCourses__head {
  padding: 10px;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
  position: relative;
  background-image: url('https://placekitten.com/1200/800');
  background-size: cover;
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.CardCourses__head::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 10px 10px 0 0;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}
.CardCourses__btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.6rem;
  background: #00bb2d;
  color: var(--white);
  cursor: pointer;
  transform: translateY(-20px);
  float: right;
  margin-right: 10px;
}
.ProgressBar {
  border-radius: 15px;
  border: 1px solid var(--green);
  height: 20px;
  /* margin: 0 auto 10px; */
}
.CardCourses__modal {
  width: 340px;
  background: var(--white);
  border-top: none;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  padding: 10px 0 0 10px;
}
.CardCourses__modal i {
  font-size: 2rem;
}
.CardCourses__Mnumber {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid var(--purple);
}

.SearchContainer__main {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.SearchContainer__main .searchContainer {
  border: none;
  width: 300px;
}
