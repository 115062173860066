.Preference {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  background: var(--white);
  padding: 20px;
  text-align: start;
  border-radius: 20px;
  font-size: 2rem;
}
.Preference__List {
  margin-left: 20px;
}
.Preference__btn {
  display: flex;
  justify-content: space-around;
}
.Preference__btn button {
  margin-top: 20px;
  padding: 6px 20px;
  margin-right: 20px;
  border-radius: 10px;
  text-decoration: none;
  background: var(--orange2);
  color: var(--white);
  font-size: 1.6rem;
  border: none;
}
