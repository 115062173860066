.SubCategories__list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.SubCategories__select {
  padding: 10px;
  font-size: 1.6rem;
  border: 1px solid var(--green);
  border-radius: 5px;
}
.NavCategory {
  border: 1px solid var(--green);
  width: fit-content;
  border-radius: 10px;
  cursor: pointer;
}
.SubCategories__list .searchContainer {
  border-radius: 5px;
  padding: 2px 8px;
}
.SubCategories__ListCourses {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 15px;
  background: #eff0f3;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
}
@media screen and (max-width: 1024px) {
  .SubCategories__ListCourses {
    grid-template-columns: 1fr;
    padding: 10px;
    gap: 15px;
  }
}
