.NewHome__coupon {
  position: fixed;
  z-index: 20;
  top: 200px;
  right: 5px;
  background-color: white;
  padding: 15px 20px;
  border-radius: 10px;
  color: black;
}
.NewHome__CLink {
  text-decoration: none;
  display: flex;
  justify-content: center;
  gap: 10px;
  /* align-items: center; */
}
.NewHome__coupon .NewHome__CImg img {
  height: 110px;
}
.NewHome__CHr {
  border-right: 1px solid var(--gray);
}
.NewHome__coupon i {
  font-size: 3rem;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
.NewHome__coupon h4 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 900;
  margin: 0;
  color: black;
}
.NewHome__coupon p {
  font-size: 1.6rem;
  text-align: center;
  margin: 0;
  font-weight: 600;
  color: #39991c;
}
.NewHome__coupon p span {
  color: var(--orange2);
}
.NewHome__head {
  min-height: 100vh;
}
.NewHome__aboutUs {
  padding: 40px 30px;
  min-height: calc(100vh - 80px);
  background-size: cover;
  background-image: url("https://res.cloudinary.com/pataformacgi/image/upload/c_scale,h_700/v1674760868/assets/FONDO_BLANCO_jhhzrw.png");
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 35px;
}
.NewHome__content {
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: space-between;
}
.CourseSection {
  width: 50%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.CourseSection__back {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 0.2s ease-out;
  cursor: pointer;
}
.CourseSection__back--isNew {
  box-sizing: border-box;
  position: relative;
}
.CourseSection__back--isNew::before {
  position: absolute;
  top: 49px;
  left: -62px;
  display: block;
  box-sizing: border-box;
  content: "¡Nuevo!";
  text-transform: uppercase;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
  color: #fff;
  background: transparent;
  height: 0px;
  border: 50px solid transparent;
  border-bottom: 50px solid var(--green);
  line-height: 50px;
  transform: rotate(-45deg);
  box-shadow: 0 4px 2px -2px gray;
}

.CourseSection__back:hover {
  opacity: 0.9;
}
.CourseSection__icon {
  font-size: 10rem;
  color: var(--white);
}
.CourseSection__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.CourseSection__text h4 {
  font-size: 3.2rem;
  font-weight: 900;
  text-align: center;
  margin: 0;
}
.CourseSection__text p {
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
  text-align: justify;
  max-height: 0;
  overflow: hidden;
  margin: 0 40px;
  transition: max-height 0.8s ease-out;
}
.CourseSection__text h3 {
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
  text-align: justify;
  margin: 0 40px;
}
.CourseSection__back:hover p {
  max-height: 1500px;
}
.CourseSection__text span {
  color: var(--white);
}
.CourseSection__button {
  border: none;
  font-size: 2rem;
  font-weight: 700;
  background: none;
  cursor: pointer;
  color: white;
  border: 2px solid white;
  padding: 15px 15px 10px 15px;
  border-radius: 10px;
  transition: 0.3s ease-out;
  width: 100%;
}
.CourseSection__button--gradient {
  background: linear-gradient(100deg, var(--purple), rgba(92, 214, 189, 1));
}
.CourseSection__button:hover {
  background: white;
  color: black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px) scale(1.1);
}
.CourseSection__button--gradient:hover {
  background: linear-gradient(100deg, var(--purple), rgba(92, 214, 189, 1));
}

.CourseSection__button--activate {
  box-shadow: 0 0 25px #ffffffa3;
  position: relative;
}

.CourseSection__button--activate::before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #ffffff84;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.CourseSection__button--activate:hover::before,
.CourseSection__button--activate:focus::before {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

.NewHome__AText p {
  font-size: 1.9rem;
}
.NewHome__AImage {
  width: 600px;
}
.NewHome__listSection {
  padding: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 35px;
}
.CardSection {
  background: #eeeeee;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  width: 350px;
}
.CardSection__head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border-radius: 15px;
  min-width: 320px;
  margin-bottom: 10px;
}
.CardSection .CardSection__text {
  font-size: 1.6rem;
  text-align: center;
}
.NewHome__contact p {
  font-size: 1.6rem;
}
.NewHome__contactContain {
  padding: 40px 30px;
  min-height: 100vh;
  background-size: cover;
  background-image: url("https://res.cloudinary.com/pataformacgi/image/upload/c_scale,h_700/v1674760868/assets/FONDO_BLANCO_jhhzrw.png");
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 35px;
}
.NewHome__contactMap {
  min-width: 50%;
}
@media screen and (max-width: 1200px) {
  .NewHome__AImage {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .NewHome__aboutUs {
    flex-direction: column;
    gap: 10px;
  }
  .CourseSection__back--isNew::before {
    top: -21px;
    left: -66px;
  }
}
@media screen and (max-width: 900px) {
  .NewHome__head {
    min-height: auto;
  }
  .EducationVirtual {
    margin-top: 0;
  }
  .EducationVirtual h2 {
    text-align: center;
  }
  .NewHome__AText p {
    font-size: 1.6rem;
  }
  .NewHome__contactContain {
    flex-direction: column-reverse;
  }
  .NewHome__contactMap {
    min-width: auto;
    width: 100%;
  }
  .NewHome__content {
    flex-direction: column;
  }
  .CourseSection {
    min-height: auto;
    height: auto;
    width: 100%;
  }
  .CourseSection__back {
    min-height: auto;
    height: auto;
    padding: 100px 0;
  }
  .NewHome__AImage {
    width: 350px;
  }
}
@media screen and (max-width: 425px) {
  .CardSection {
    width: 100%;
  }
  .CardSection__head {
    min-width: auto;
  }
  .NewHome__listSection {
    padding: 30px 10px;
    gap: 15px;
  }
  .NewHome__AImage {
    width: 90%;
  }
}
