.CustomButton__modal {
  background: var(--white);
  padding: 10px 50px;
}
.CustomButton__list {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.CustomButton__Memail {
  border: 1px solid var(--green);
  font-size: 1.6rem;
  padding: 5px 10px;
  border-radius: 15px;
}
.RenderChildren {
  background: var(--white);
  font-size: 1.6rem;
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
  border: 1px solid var(--green);
}
.CustomButton__Memail i {
  color: #c93f3f;
  font-size: 1.8rem;
  cursor: pointer;
}
.CustomButton__Memail i:hover {
  color: #c93f3f85;
}
