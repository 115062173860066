.Institutions {
  padding: 15px 2%;
  text-align: center;
}
.Institutions h2 {
  font-size: 3rem;
  margin: 0 0 10px;
}
.Institutions__btn {
  display: flex;
  gap: 10px;
}
.Institutions__btn > a {
  width: 100%;
}
