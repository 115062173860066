.ScheduleCourse__classDate {
  border: 1px solid var(--green);
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: block;
  text-decoration: none;
}
.ScheduleCourse__classDate:hover {
  background-color: var(--green);
}
.ScheduleCourse__classDate:hover .H3 {
  color: white !important;
}
.ScheduleCourse__classTime a {
  color: var(--orange2);
}
.ScheduleCourse__classDate:hover a {
  color: white;
}
.ScheduleCourse__classTime {
  display: flex;
  justify-content: space-between;
}
