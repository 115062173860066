.FilesForLesson__file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  gap: 5px;
  margin-bottom: 10px;
}
.FilesForLesson__file a {
  text-decoration: none;
  font-size: 1.4rem;
  color: var(--green);
  width: 100%;
  padding: 7px 5px;
  border-radius: 5px;
  font-weight: 500;
  border: 1px solid var(--green);
}
.FilesForLesson__file .FilesForLesson__btns {
  display: flex;
  gap: 5px;
}
.FilesForLesson__file .FilesForLesson__btns button {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
}
.FilesForLesson__file .FilesForLesson__btns button:hover {
  filter: brightness(1.2);
  transform: scale(1.05);
}
.FilesForLesson__file .FilesForLesson__btns-edit {
  background: #f4b61846;
  color: var(--yellow);
}
.FilesForLesson__file .FilesForLesson__btns-delete {
  background: #c93f3f46;
  color: #c93f3f;
}
