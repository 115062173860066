.UserInstitution .UserInstitution__title {
  font-size: 2rem;
  /* margin: 10px 0 0; */
}
.UserInstitution__info h2 {
  font-size: 2rem;
  margin-bottom: 25px;
}
.UserInstitution__info h3 {
  font-size: 1.6rem;
  font-weight: 500;
}
.UserInstitution__info button {
  font-size: 1.6rem;
}
