/* .EditorView h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0 0 5px;
}
.EditorView h1 {
  font-size: 2rem;
}
.EditorView h2 {
  font-size: 1.8rem;
}
.EditorView h3 {
  font-size: 1.6rem;
}
.EditorView h4,
h5,
h6 {
  font-size: 1.4rem;
}
.EditorView p,
li {
  font-size: 1.4rem;
} */
.EditorView {
  font-size: 1.6rem;
}
.EditorView table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.EditorView table th,
td {
  border: 1px solid var(--green);
  padding: 10px;
  text-align: left;
}
.EditorView p {
  overflow-wrap: anywhere;
}
