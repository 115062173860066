@media screen and (min-width: 1500px) {
  html {
    font-size: 62.5%;
  }
}
.ControlSVG {
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ControlSVG__text {
  position: absolute;
  z-index: 1;
  margin-bottom: 40px;
}
