.EditPerfil {
  font-size: 1.6rem;
}
.EditPerfil h2 {
  text-align: center;
  margin-bottom: 0;
}
.EditPerfil {
  text-align: start;
}
.EditPerfil__label {
  font-size: 1.2rem;
  color: var(--gray);
}
.EditPerfil__btn {
  margin-bottom: 20px;
}
.EditPerfil__btn button {
  padding: 6px 20px;
  margin-right: 20px;
  border-radius: 10px;
  text-decoration: none;
  color: var(--color6);
  border: none;
}
.EditPerfil__btnG {
  background: linear-gradient(var(--color4), var(--color3));
}
.EditPerfil__btnC {
  background: linear-gradient(var(--color3), var(--color2));
}
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.PhoneInputCountryIcon {
  width: 30px;
  height: 21px;
}
.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: 5px;
  height: 5px;
  margin-left: 5px;
  border: solid;
  border-top-width: 0;
  border-bottom-width: 3px;
  border-left-width: 0;
  border-right-width: 3px;
  transform: rotate(45deg);
  opacity: 0.8;
}

.EditPerfil .PhoneInput {
  border: 1px solid #bbbbbb;
  background: var(--white);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2px 5px;
  border-radius: 5px;
}
.EditPerfil .PhoneInput input {
  border: none;
  outline: none;
  font-size: 1.6rem;
  width: 100%;
}
.PhoneInput {
  border: 1px solid #bbbbbb;
  background: var(--white);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2px 5px;
  border-radius: 5px;
  margin: 1rem;
}

.PhoneInput input {
  border: none;
  outline: none;
  font-size: 1.6rem;
  width: 100%;
}
