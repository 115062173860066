.checkP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.2);
  margin: 8px 0;
  border-radius: 10px;
  padding: 4px 10px 0 10px;
  color: var(--black);
  font-size: 1.6rem;
}
.checkP__span {
  margin-top: 6px;
}
.checkP__toggle input[type="checkbox"] {
  position: relative;
  width: 60px;
  background: #c93f3f;
  height: 25px;
  -webkit-appearance: none;
  border-radius: 20px;
  outline: none;
  transition: 0.4s;
  cursor: pointer;
}
.checkP__toggle input:checked[type="checkbox"] {
  background: var(--green);
}
.checkP__toggle input[type="checkbox"]::before {
  z-index: 0;
  position: absolute;
  content: "";
  left: 0;
  margin: 2px 0 0 2px;
  width: 20px;
  height: 20px;
  background: var(--white);
  border-radius: 50%;
  transform: scale(1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.checkP__toggle input:checked[type="checkbox"]::before {
  left: 35px;
}
.checkP__toggle {
  position: relative;
  display: inline;
}
.checkP__toggle label {
  position: absolute;
  color: var(--white);
  pointer-events: none;
}
.onbtn {
  bottom: 10px;
  left: 8px;
}
.ofbtn {
  bottom: 10px;
  right: 8px;
}
