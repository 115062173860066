.InstitutionCourse {
  padding: 0 5% 0 3%;
}
.InstitutionCourse h3 {
  font-size: 2rem;
}
.InstitutionCourse h4 {
  font-size: 1.6rem;
  text-align: start;
  font-weight: normal;
}
