.TemplateAgenda {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 10px; */
}
.TemplateAgenda__Link {
  display: flex;
  text-align: start;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: calc(100% - 8px);
  padding: 8px;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  background: var(--white);
}
.TemplateAgenda__date h5 {
  font-size: 2rem;
  margin: 0;
  text-align: center;
  font-weight: normal;
  color: var(--green);
  text-transform: capitalize;
}
.TemplateAgenda__date h4 {
  font-size: 1.6rem;
  font-weight: normal;
  margin: 0;
  color: #4d4d4d;
}
.TemplateAgenda__main h3 {
  font-size: 1.6rem;
  margin: 0 0 5px;
  text-transform: uppercase;
  color: var(--black);
}
.TemplateAgenda__main h4 {
  font-size: 1.6rem;
  font-weight: normal;
  margin: 0;
  color: #4d4d4d;
}
.TemplateAgenda__Link.homework {
  border: 2px solid var(--green);
}
.TemplateAgenda__Link.quiz {
  border: 2px solid var(--orange2);
}
.TemplateAgenda__Link.virtual {
  border: 2px solid var(--purple);
}
.TemplateAgenda__Link.homework.isToday {
  background: #469585a1;
}
.TemplateAgenda__Link.quiz.isToday {
  background: #ee7c38a1;
}
.TemplateAgenda__Link.virtual.isToday {
  background: #773b8ea1;
}
.TemplateAgenda__Link.isToday h5 {
  color: var(--white2);
}
.TemplateAgenda__Link.isToday h4 {
  color: var(--black);
}
.TemplateAgenda__Link:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  transform: scale(1.02);
  transition-duration: 0.3s;
  background: var(--white);
  border: 1px solid var(--white);
}
.TemplateAgenda__card {
  width: calc(100% - 8px);
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
}
.TemplateAgenda__card:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  transform: scale(1.02);
  transition-duration: 0.3s;
}
.TemplateAgenda__card.module:hover {
  background: var(--white);
  border: 1px solid var(--white);
}
.TemplateAgenda__card.module {
  border: 1px solid gray;
  background: var(--white);
  text-align: start;
}
.TemplateAgenda__card.module h3 {
  font-size: 1.6rem;
  margin: 0 0 10px;
  color: var(--black);
}
.TemplateAgenda__card .TemplateAgenda__date {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}
