.Webinar {
  margin-top: 5px;
}
.Webinar__header {
  background-color: var(--orange1);
  padding: 20px;
  text-align: center;
  font-size: 2rem;
  color: white;
}
.Webinar__hero {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  padding: 10px 30px;
}
.Webinar__ImageContain {
  width: 40%;
  text-align: center;
}
.Webinar__HImage {
  margin: auto;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-image: url('https://img.freepik.com/fotos-premium/mujer-mano-dinero-computadora-mesa_218381-3280.jpg?w=2000');
  background-size: cover;
  background-repeat: no-repeat;
}
.Webinar__HDescription {
  width: 60%;
  gap: 10px;
  display: flex;
  padding: 30px 40px;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.Webinar__HDescription img {
  width: 150px;
}
.Webinar__section {
  background: rgb(120, 209, 209);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 10px;
  flex-wrap: wrap;
  gap: 15px;
}
.Webinar__SItem {
  text-align: center;
}
.Webinar__SItem i {
  font-size: 4rem;
  margin-bottom: 10px;
}
.Webinar__SItem .Webinar__SIImage {
  height: 45px;
  width: 45px;
  background-image: url('https://res.cloudinary.com/pataformacgi/image/upload/v1670953990/assets/WhatsApp_Image_2022-12-13_at_13.51.56_mqijzt.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 5px;
}
.Webinar__description {
  background-color: rgb(250, 241, 226);
  padding: 40px 30px;
}
.Webinar__DList {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 20px 5px;
}
.CardSkill {
  max-width: 200px;
  padding: 20px;
  font-size: 3rem;
  min-height: 220px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid var(--orange1);
}
.CardSkill i {
  margin-bottom: 15px;
  color: gray;
}
.Webinar__teacher {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  padding: 0 30px;
}
.Webinar__TImage {
  width: 20%;
  border-radius: 20px;
}
.Webinar__TDescription {
  margin-top: 15px;
  text-align: center;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.Webinar__footer {
  background: rgb(120, 209, 209);
  text-align: center;
  padding: 30px;
}
.Register__image {
  height: 80px;
  width: 80px;
  background-image: url('https://res.cloudinary.com/pataformacgi/image/upload/v1670953990/assets/WhatsApp_Image_2022-12-13_at_13.51.56_mqijzt.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 5px;
}
@media screen and (max-width: 900px) {
  .Webinar__ImageContain {
    display: none;
  }
  .Webinar__HDescription {
    width: 90%;
  }
  .Webinar__teacher img:first-child {
    display: none;
  }
  .Webinar__TImage {
    width: 40%;
    border-radius: 20px;
  }
  .Webinar__footer {
    padding: 30px 0;
  }
  .Webinar__footer .H3 {
    width: 80% !important;
  }
}
@media screen and (max-width: 425px) {
  .Webinar__description .H2 {
    padding: 10px 0 !important;
  }
  .Webinar__teacher img {
    display: none;
  }
  .Webinar__footer .H3 {
    width: 90% !important;
    font-size: 2rem !important;
  }
}
