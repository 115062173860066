.VideoContent__controls {
  width: 100%;
  height: 85%;
  background: #bbbbbb;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.VideoContent__btns {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.VideoContent__btns i {
  font-size: 6rem;
  color: var(--green);
  cursor: pointer;
}
.VideoControls__play {
  /* background: var(--orange2); */
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 140px;
  border-radius: 50%;
}
.VideoControls__play i {
  font-size: 5rem;
  color: var(--purple);
  margin-left: 10px;
}
.VideoContent__container {
  width: 100%;
  height: 75%;
  border-radius: 20px;
}
.VideoContent {
  display: flex;
  gap: 10px;
}
.VideoContent__video {
  width: 70%;
  height: calc(100vh - 100px);
  background: #f8f8f8;
  border-radius: 10px;
  padding: 15px;
}
.VideoContent__aside {
  width: 30%;
  min-width: 30%;
  text-align: center;
  background: #f8f8f8;
  height: calc(100vh - 100px);
  border-radius: 10px;
  padding: 15px;
  overflow-y: auto;
  scrollbar-color: var(--green) transparent;
  scrollbar-width: thin;
}
.VideoContent__aside::-webkit-scrollbar {
  width: 1rem;
}
.VideoContent__aside::-webkit-scrollbar-track {
  background: transparent;
  margin: 20px 0;
}
.VideoContent__aside::-webkit-scrollbar-thumb {
  background: var(--green);
  border-radius: 25px;
}
@media screen and (max-width: 1024px) {
  .VideoContent__container {
    width: calc(100vw - 0);
  }
  .VideoContent {
    flex-direction: column;
  }
  .VideoContent > div {
    width: auto;
  }
  .VideoContent__video {
    height: calc(100vh - 180px);
  }
}
