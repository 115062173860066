.ContentCertificate {
  border-radius: 10px;
  background-image: url('https://res.cloudinary.com/dzxmzxvbr/image/upload/v1615473018/cetificate_z9x1ur.png');
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.ContentCertificate__content {
  background: #193765d9;
  border-radius: 10px;
  padding: 10px 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.ContentCertificate:hover {
  box-shadow: 0px 0px 10px rgba(70, 149, 133, 0.5);
  transition: border 0.3s;
}
.ContentCertificate__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ContentCertificate__head h3 {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: start;
}
.ContentCertificate__imgs {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ContentCertificate__imgs img {
  /* mix-blend-mode: hard-light; */
  height: 40px;
}
.ContentCertificate .ContentCertificate__content h2 {
  margin: 0;
  font-size: 2rem;
}
.ContentCertificate .ContentCertificate__content p {
  margin: 0;
  font-size: 1.6rem;
}
.ContentCertificate__btns {
  display: flex;
  gap: 10px;
  align-items: center;
}
.ContentCertificate__btns button {
  width: 50%;
}
.ContentCertificate__btns .Loading {
  margin: 0 auto;
}
.ContentVirtualClass__modal h2 {
  margin: 0;
  font-size: 2.5rem;
}
.ContentVirtualClass__modal a {
  color: var(--orange2);
}
.ContentCertificate__check .form-control {
  display: flex;
  flex-direction: row-reverse;
  /* justify-content: start; */
  font-size: 3rem;
}
.ContentCertificate__check .form-control label {
  font-size: 1.6rem;
}
@media screen and (max-width: 600px) {
  .ContentCertificate__head {
    flex-direction: column-reverse;
    gap: 10px;
    margin-bottom: 10px;
    text-align: start;
    align-items: flex-start;
  }
  .ContentCertificate__imgs {
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
  }
  .ContentCertificate__imgs img {
    height: 30px;
  }
  .ContentCertificate__btns {
    flex-direction: column-reverse;
    gap: 0;
  }
  .ContentCertificate__btns button {
    width: 100%;
  }
}
