.AnswersContent__header {
  display: flex;
  justify-content: space-between;
  padding: 10px 1%;
}
.AnswersContent__btn {
  text-align: center;
}
.AnswersContent__information h2 {
  font-size: 3rem;
  margin: 0;
}
.AnswersContent__information ul li {
  font-size: 1.6rem;
  margin: 5px 0;
}
.AnswersContent__grades {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 2.5rem;
  padding: 10px 20px;
  gap: 20px;
}
.AnswersContent__grades.correct {
  background: #469585a1;
}
.AnswersContent__grades.wrong {
  background: #c93f3fa1;
}
.AnswersContent__grades .AnswersContent__hr {
  height: 100%;
  border: rgba(0, 0, 0, 0.6) 1px solid;
}
.AnswersContent__Qualification {
  text-align: center;
}
.AnswersContent__Qualification p {
  margin: 0;
  font-size: 2rem;
}
.AnswersContent__Hits {
  text-align: center;
}
.AnswersContent__Hits h3 {
  font-size: 2.5rem;
}
.AnswersContent__Hits p {
  margin: 0;
  font-size: 1.8rem;
}
.AnswersContent .NormalLink {
  margin-top: 20px;
  text-align: center;
}
.AnswersContent .NormalLink a {
  font-size: 2rem;
}
.AnswersContent__query {
  width: 900px;
  background: var(--white);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 10px;
  margin: 10px auto;
}
.AnswersContent__query.correct {
  border: 1px solid var(--green);
}
.AnswersContent__query.wrong {
  border: 1px solid #c93f3f;
}
.AnswersContent__query h2 {
  font-weight: 500;
  font-size: 2rem;
  margin: 0;
}
.AnswersContent__question {
  margin: 10px 1%;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  border: rgba(0, 0, 0, 0.5) 2px solid;
  color: rgba(0, 0, 0, 0.8);
}
.AnswersContent__question img {
  height: 100px;
  border-radius: 5px;
}
.AnswersContent__relation {
  display: flex;
}
.AnswersContent__relation .AnswersContent__question {
  width: 50%;
}
.AnswersContent__question.correct {
  border: var(--green) 2px solid;
  background: #4695853f;
  color: var(--black);
}
.AnswersContent__question.wrong {
  border: #c93f3f 2px solid;
  color: var(--black);
  background: #c93f3f3f;
}
.Question__img {
  height: 150px;
  border-radius: 5px;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .AnswersContent__header {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .AnswersContent__grades {
    width: auto;
    align-self: flex-end;
  }
  .AnswersContent__grades .AnswersContent__hr {
    height: 50px;
  }
  .AnswersContent__query {
    width: 90%;
    margin: 10px auto;
  }
}
@media screen and (max-width: 576px) {
  .AnswersContent__grades {
    width: auto;
    flex-direction: column;
  }
  .AnswersContent__grades .AnswersContent__hr {
    height: 100%;
    width: 100%;
    border: rgba(0, 0, 0, 0.6) 1px solid;
  }
}
