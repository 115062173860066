.CatalogueAside__list {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}
.CatalogueAside__link {
  font-size: 1.4rem;
  text-decoration: none;
  color: var(--black);
  font-weight: 500;
}
.CatalogueAside__link:hover {
  color: var(--green);
}
.CatalogueAside__categories {
  width: 95%;
}
