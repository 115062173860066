.ListChildren__child {
  /* border: 1px solid red; */
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
}
.ListChildren__child > div {
  align-items: center;
  display: flex;
  gap: 10px;
}
.ListChildren__child img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.ListChildren__child .DefaultBtn {
  background: var(--green);
  border: 1px solid var(--green);
  padding: 10px;
}
