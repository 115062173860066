.ContentVIdeo {
  border-radius: 10px;
  display: flex;
  margin: 0 auto;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  text-decoration: none;
}
.ContentVIdeo:hover {
  box-shadow: 0px 0px 10px rgba(70, 149, 133, 0.5);
  transition: border 0.3s;
}
.ContentVIdeo__img {
  border-radius: 9px 0 0 9px;
  background: gray;
  width: 40%;
  height: 200px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.ContentVIdeo__img i {
  color: var(--white);
  font-size: 3rem;
}
.ContentVIdeo__main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.ContentVIdeo__title {
  margin: 0;
  font-size: 1.6rem;
}
.ContentVIdeo__time {
  font-size: 1.4rem;
}
.ContentVIdeo__time i {
  margin-right: 8px;
}
.ContentVIdeo__act {
  position: relative;
}
.ContentVIdeo__block {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ContentVIdeo__block h3 {
  /* text-align: end; */
  margin: 10px 0 0 10px;
  color: var(--white);
  font-size: 1.6rem;
}
.ContentVIdeo__block i {
  font-size: 3rem;
  height: 30px;
  width: 30px;
  color: var(--white);
  border: 1px solid var(--white);
  padding: 15px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto 10px;
}
