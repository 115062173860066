.FormNewFollow {
  width: 48%;
  min-width: 330px;
}
.FormNewFollow__card {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  border-radius: 10px;
}

.RadioButtons label {
  font-size: 12px;
}
.FormNewFollow__card_information_medium label i {
  font-size: 2.5rem;
}
.RadioButtons__input .FormNewFollow__card_information_medium .RadioButtons__input {
  width: 50px;
}
.FormNewFollow__card_information_medium .RadioButtons__input label {
  text-align: center;
}
.FormNewFollow__card .sendMaterial label {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
}
.FormNewFollow__card.interes_level .RadioButtons > label {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
}

/* .RadioButtons__input #input[type="radio"]:checked + label {
  background: var(--black);
  color: var(--white);
  border: 1px solid var(--black);
} */

/* .RadioButtons__input > #2-amarillo input[type="radio"]:checked + label {
  background: var(--yellow);
  color: var(--black);
  border: 1px solid var(--yellow);
} */
.RadioButtons__input:nth-child(1) #1-amarillo input[type="radio"]:checked + label {
  background: var(--blue);
  color: var(--black);
  border: 1px solid var(--blue);
}
