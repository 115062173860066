.QuizEdit {
  text-align: center;
}
.QuizEdit__questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.QuizEdit__question {
  width: 900px;
  background: #f8f8f8;
  border-radius: 10px;
  padding: 15px;
}
.QuizEdit__question img {
  height: 100px;
  background-size: cover;
  border-radius: 10px;
  border: 1px solid gray;
}
.QuizEdit__header {
  gap: 15px;
  display: flex;
  margin-bottom: 5px;
  align-items: flex-start;
  justify-content: space-between;
}
.QuizEdit__header .QuizEdit__icon {
  font-size: 2rem;
  cursor: pointer;
  padding: 0 8px;
  color: var(--green);
  border-radius: 5px;
  border: 1px solid var(--green);
}
.QuizEdit__header .QuizEdit__icon:hover {
  color: var(--white);
  background: var(--green);
}
.QuizEdit__Inquiries {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.QuizEdit__Relation {
  display: flex;
  justify-content: space-between;
}
.QuizEdit__Inquiry {
  padding: 10px 8px 8px;
  font-size: 1.6rem;
  border-radius: 5px;
  border: 1px solid gray;
  text-align: start;
  margin: 0;
  font-weight: 500;
  color: gray;
}
.QuizEdit__Relation .QuizEdit__Inquiry {
  width: 50%;
  max-width: calc(50% - 20px);
}
.QuizEdit__Inquiry.corrent {
  background: var(--green);
  border: 1px solid var(--green);
  color: var(--white);
}
@media screen and (max-width: 900px) {
  .QuizEdit__question {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
  }
  .QuizEdit__questions {
    margin-bottom: 15px;
  }
}
