.ComentCheck {
  background-color: #f8f8f8;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
}
.ComentCheck__form {
  width: 80%;
  margin: 0 auto;
  text-align: right;
}
.ComentCheck__form i {
  font-size: 2rem;
  color: var(--red);
  cursor: pointer;
}
