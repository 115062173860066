.StatementMain {
  background: var(--white);
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1.6rem;
}

.StatementMain__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.StatementMain__header img {
  height: 50px;
}

.StatementMain__title {
  text-align: center;
  font-size: 2rem;
  text-decoration: underline;
}

.StatementMain__date {
  text-align: end;
  font-size: 1.6rem;
}

.StatementMain__main {
  font-size: 1.6rem;
  padding: 0;
  list-style: none;
}

.StatementMain__main li {
  padding: 0;
  font-size: 1.6rem;
  text-align: justify;
  margin: 10px 0;
  line-height: 1.5;
}

.StatementMain__fired {
  text-align: end;
}

.StatementMain__content {
  text-align: start;
}

.StatementMain__img {
  padding: 0px;
  margin-bottom: 10px;
  text-align: center;
}

.StatementMain__img img {
  max-width: 100%;
  max-height: 750px;
  border-radius: 5px;
}