.DataSubtitle {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
}

.DataSubtitle h4 {
  font-size: 1.6rem;
  margin: 0;
}

.DataSubtitle.text,
.date,
.img,
.anchor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.DataSubtitle.text,
.date h3 {
  margin: 0;
  font-size: 1.6rem;
  font-weight: 500;
}

.DataSubtitle.anchor a {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--orange2);
}

.DataSubtitle.section h3 {
  margin: 10px 0 0;
  font-size: 1.6rem;
  font-weight: 500;
}

.DataSubtitle.section span {
  color: var(--orange2);
  cursor: pointer;
}

.DataSubtitle.img img {
  height: 100px;
}