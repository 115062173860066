.ButtonFloating {
  background: #00bb2d;
  width: 70px;
  height: 70px;
  bottom: 0;
  position: fixed;
  right: 0;
  border-radius: 50%;
  text-align: center;
  margin: 10px;
  cursor: pointer;
}
.ButtonFloating:hover {
  background: #0fce3b;
}
.ButtonFloating i {
  font-size: 5rem;
  color: var(--white);
  line-height: 70px;
}
.CardFloating {
  background: var(--white2);
  border: solid 1px #00bb2d;
  width: 300px;
  /* height: 300px; */
  position: fixed;
  /* position: absolute; */
  bottom: 0;
  right: 0;
  border-radius: 10px;
  text-align: center;
  margin: 10px;
}
.CardFloating__header {
  padding: 0px 10px;
  background: #00bb2d;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  color: var(--white);
}
.CardFloating__header i {
  font-size: 2.8rem;
  margin-right: 5px;
}
.CardFloating__title {
  display: flex;
  align-items: center;
}
.CardFloating__close {
  border-radius: 50%;
  cursor: pointer;
  margin: 10px 0;
}
.CardFloating__close i {
  font-size: 1.6rem;
}
.CardFloating__main {
  background: var(--white);
  margin: 10px 20px;
  padding: 10px;
  border-radius: 10px 10px 10px 0;
  box-shadow: 20px 20px 20px 15px rgba(0, 0, 0, 0.1);
}
.CardFloating__main ul {
  padding-left: 20px;
}
.CardFloating__main li {
  text-align: start;
  font-size: 1.4rem;
}
.CardFloating__btn {
  height: 30px;
  text-align: end;
  margin: 15px 20px 0;
}
.CardFloating__btn a {
  background: #00bb2d;
  color: var(--white);
  padding: 10px;
  font-size: 1.4rem;
  text-decoration: none;
  border-radius: 10px;
}
