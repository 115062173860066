.Course__title {
  font-size: 2.5rem;
}
.Course__subTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  font-size: 1.6rem;
  cursor: pointer;
  margin: 10px 0 5px;
  padding: 15px 5px 10px;
  border-radius: 10px;
  border: 1px solid rgb(196, 196, 196);
}
.Course__subTitle:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
}
.Course__subTitle h3 {
  font-weight: 600;
  margin: 0;
}
.Course__main {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.ModuleLections__h3 {
  font-size: 1.6rem;
  font-weight: 500;
}
