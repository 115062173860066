.ResumeContact {
  display: flex;
  margin-top: 70px;
  gap: 10px 0;
}
.ResumeContact__user {
  position: fixed;
  background: var(--orange2);
  width: 230px;
  height: calc(100vh - 75px);
  min-height: calc(100vh - 75px);
  text-align: center;
  border-radius: 15px;
  margin: 0 0 5px 5px;
}
.ResumeContact__separe {
  width: 230px;
}
.ResumeContact__user .ResumeContact__imgUser {
  width: 100px;
  margin: 10px 0 0;
  border-radius: 10px;
  background: var(--white);
  padding: 1px;
}
.ResumeContact__main {
  padding: 10px 20px;
}
.ResumeContact__content {
  width: calc(100% - 230px);
}
.ResumeContact__rangeDate {
  display: flex;
  margin-right: 30px;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}
.CardRegister {
  padding: 15px 20px;
  border-radius: 5px;
}
.ResumeContact__detail {
  display: flex;
  justify-content: flex-end;
  margin-right: 35px;
}
.TeamProgress {
  width: 400px;
  margin: 0 auto;
  border: 1px solid var(--gray);
  padding: 10px;
  border-radius: 5px;
}
.PersonalProgress__item {
  width: 450px;
  margin: 15px 0;
}
.PersonalProgress__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.NameAndPoint {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 768px) {
  .ResumeContact {
    flex-direction: column;
  }
  .ResumeContact__user {
    position: static;
    height: auto;
    min-height: auto;
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    border-radius: 15px;
  }
  .ResumeContact__user .ResumeContact__imgUser {
    width: 100px;
    margin: 0;
    border-radius: 10px;
    padding: 2px;
    height: auto;
  }
  .ResumeContact__content {
    width: 95%;
    margin: 0 auto;
  }
  .PersonalProgress__item {
    width: 95%;
    margin: 15px 0;
  }
}
