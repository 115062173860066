.IconBtn {
  background: #4695851a;
  border: none;
  height: 40px;
  border-radius: 5px;
  color: var(--green);
  font-size: 1.8rem;
  cursor: pointer;
}
.IconBtn:hover {
  background: #469585a1;
  color: var(--white);
}
.IconBtn:disabled {
  opacity: 0.2;
}
.IconBtn:disabled {
  opacity: 0.2;
}
.IconBtn[disabled]:hover {
  background: #4695851a;
  color: var(--green);
}
.IconBtn i {
  margin: 0 5px;
}
.IconBtn h3 {
  margin: 0 5px;
  line-height: 40px;
  font-size: 2rem;
  font-weight: 500;
}
.IconBtn span {
  font-size: 1.6rem;
}
