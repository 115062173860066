.CardIcon {
  border: 2px solid var(--green);
  background: #4595861a;
  color: var(--green);
  text-decoration: none;
  padding: 20px 0;
  border-radius: 10px;
  text-align: center;
}
.CardIcon:hover {
  background: var(--green);
  color: var(--white);
}
.CardIcon i {
  font-size: 4rem;
}
.CardIcon h4 {
  font-size: 3rem;
  margin: 5px 0 0;
  font-weight: normal;
}
