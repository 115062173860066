.Comment {
  padding: 10px;
  background: var(--white);
  margin: 10px 0;
  border-radius: 5px;
}
.Comment__Mheader {
  display: flex;
  align-items: center;
  gap: 15px;
}
.Comment__Mheader img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.ViewComment__head {
  display: flex;
  justify-content: space-between;
}
.Comment__Mborder {
  width: 100%;
  padding: 8px 15px;
  border-radius: 50px;
  background-color: var(--white);
  cursor: pointer;
}
.Comment__Manchor {
  margin: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--green);
  width: fit-content;
}
.Comment__Mcard {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 10px;
  /* text-align: end; */
}
.FormComent {
  text-align: end;
}
.FormComent i {
  font-size: 2rem;
  color: #c93f3f;
  cursor: pointer;
}
.Comment__responses {
  padding: 10px 15px 0;
}
