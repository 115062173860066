.ListCourses {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ListCourses__item {
  color: var(--green);
  text-decoration: none;
  font-size: 1.6rem;
  border: 1px solid var(--green);
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
  text-align: start;
}
.ListCourses__item:hover {
  background: var(--green);
  color: var(--white);
}
.ViewUser__anchor {
  background: var(--green);
  text-decoration: none;
  color: var(--white);
  font-size: 2rem;
  padding: 3px 5px;
  border-radius: 10px;
}
.ViewUser__copy {
  padding: 4px 5px;
  font-size: 1.6rem;
  border-radius: 5px;
  color: gray;
  cursor: pointer;
}
.CardChildren {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.CardChildren:hover {
  border: 1px solid var(--green);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.CardChildren__img {
  width: 50px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 50%;
  background-size: contain;
}
