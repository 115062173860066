.TableDefault {
  margin: 10px 0;
}
.TableDefault__header {
  display: grid;
  border-bottom: 1px solid #afafaf;
  gap: 10px;
}
.TableDefault__header .TableDefault__head {
  margin: 0;
  font-weight: 600;
  color: #afafaf;
  font-size: 1.6rem;
}
.TableDefault__cell {
  cursor: pointer;
  display: grid;
  color: #5f5f5f;
  font-weight: 500;
  font-size: 1.4rem;
  border-radius: 10px;
}
.TableDefault__cell > div {
  padding: 15px 10px;
}
.TableDefault__cell_borderBottom {
  border-bottom: 0.8px solid var(--green);
  border-radius: 0px;
}
.TableDefault__cell:hover {
  background: #4595861c;
}
.TableDefault__cell-activate {
  background: #4595861c;
  color: var(--green);
  font-weight: 700;
}
.TableDefault__cell div {
  overflow: hidden;
  text-overflow: ellipsis;
}
.TableDefault__cell img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid var(--white);
}
.TableDefault__cell-activate img {
  border: 1px solid var(--green);
}
.TableDefault__cell a {
  color: var(--orange2);
}
.TableDefault__textColor h4 {
  font-weight: 500;
  width: fit-content;
  padding: 5px 8px;
  border-radius: 50px;
  color: var(--white);
}
.TableDefault__column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  gap: 8px;
}
.TableDefault__column .TableDefault__head {
  font-size: 1.6rem;
}
