.GraduateCard {
  border-radius: 15px;
  width: 100%;
  position: relative;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.GraduateCard__container {
  position: relative;
  width: 100%;
}
.GraduateCard__container .GraduateCard__cartPlus {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  height: 150px;
  color: var(--white);
  font-size: 2rem;
  padding: 0 20px;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  border-radius: 0 15px 15px 0;
  transition: visibility 0s, opacity 0.4s linear;
}
.GraduateCard__container:hover .GraduateCard__cartPlus:hover {
  color: var(--black);
  opacity: 1;
}
.GraduateCard__container:hover .GraduateCard__cartPlus {
  visibility: visible;
  opacity: 0.7;
}
.GraduateCard:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.GraduateCard__text {
  padding: 20px;
  color: var(--white);
  z-index: 1;
  position: absolute;
}
.GraduateCard__background {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
}
@media screen and (max-width: 600px) {
  .GraduateCard__text {
    padding: 10px;
  }
}
