.CircleProgress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.CircleProgress__circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
#progress-spinner {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}
#middle-circle {
  position: absolute;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  /* background-color: rgb(248, 248, 248); */
  display: flex;
  align-items: center;
  justify-content: center;
}
