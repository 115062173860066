.ListDocument__item {
  display: flex;
  gap: 5px;
  margin-top: 8px;
}
.ListDocument__item a {
  padding: 8px 0;
  border-radius: 5px;
  background: var(--green);
  color: var(--white);
  width: 100%;
  text-decoration: none;
}
.ListDocument__item a:hover {
  opacity: 0.8;
}
.ListDocument__item .DefaultBtn {
  background: #c93f3fcb;
  border: none;
}
