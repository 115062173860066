.CourseModules {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 10px 10px 5px;
}
.CourseModules__module {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.CourseModules__recuperatory {
  border: 1px solid var(--green);
  background: var(--green);
  color: var(--white);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.CourseModules__module h3 {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 600;
}
