.CourseShop {
  background: var(--white);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.CourseShop__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.CourseShop__item {
  min-width: calc(100% / 6);
}
.CourseShop__PlansPayment {
  display: flex;
  justify-content: space-around;
}
.PlanPayment__container {
  padding: 10px;
  border-radius: 10px;
}
.Shopping__aside {
  background: #458e7f4f;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.Shopping__course {
  display: flex;
}
.Shopping__section {
  background: var(--white);
  padding: 10px;
  border-radius: 10px;
}
.PlanPayment__container {
  background: var(--white2);
  transition-duration: 0.8s;
  cursor: pointer;
}
.PlanPayment__container:hover {
  background: #458e7f42;
}
.cursorPonter {
  cursor: pointer;
}
