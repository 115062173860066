.ProgramCourse {
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
}
.ProgramCourse .ButtonNavigation {
  background: none;
}
.ProgramCourse .ButtonNavigation button {
  border: 1px solid rgba(128, 128, 128, 0.474);
}
