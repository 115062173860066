.CardMainOptions {
  width: 100%;
  display: flex;
  height: auto;
  gap: 10px;
}
.CardMainOptions__main {
  background: var(--white);
  border: 1px var(--white) solid;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
  text-decoration: none;
  color: #494949;
}
.CardMainOptions__main:hover {
  border: 1px var(--green) solid;
}
.CardMainOptions__options {
  background: var(--white);
  border: 1px var(--white) solid;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 8%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 5px;
  padding: 5px 0;
}
.CardMainOptions__options:hover {
  border: 1px var(--green) solid;
}
@media screen and (max-width: 600px) {
  .CardMainOptions {
    flex-direction: column;
    max-width: 100%;
    gap: 15px;
  }
  .CardMainOptions__options {
    flex-direction: row;
    width: 100%;
    height: 35px;
  }
}
