.CardComment {
  background: var(--white);
  padding: 10px 20px;
  margin: 15px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  text-align: start;
}
.CardComment__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 15px;
}
.CardComment__header h5 {
  margin: 0;
}
.CardComment__avatar {
  display: flex;
  align-items: center;
}
.CardComment__name {
  font-size: 1.6rem;
}
.CardComment__img {
  width: 40px;
  margin-right: 10px;
  height: 40px;
  border-radius: 50%;
}
.CardComment__rol {
  color: gray;
  font-weight: 400;
  font-size: 1.5rem;
}
.CardComment__main {
  margin: 0;
  font-size: 1.4rem;
}
