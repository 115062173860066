.CourseInfo {
  margin-top: 140px;
  min-height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.CourseInfo--oea {
  margin-top: 0;
}
.CourseInfo__header {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 0 10px 10px;
}
.CourseInfo__Htitle {
  background: #f5f5f5;
  border-radius: 20px;
  padding: 20px 30px;
  width: 50%;
}
.CourseInfo__Hhead {
  padding-bottom: 10px;
  border-radius: 15px;
  width: 95%;
  margin: 0 auto;
}
.CourseInfo__Himg {
  width: 100%;
  border-radius: 15px 15px 0 0;
}
.CourseInfo__Banner {
  display: flex;
  flex-direction: column;
}
.CourseInfo__BText {
  padding: 10px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: var(--white);
  border: 1px solid #ff0000;
  border-top: none;
  border-radius: 0 0 15px 15px;
}
.CourseInfo__Hcontact {
  width: 50%;
  background: #f5f5f5;
  border-radius: 20px;
  padding: 20px 30px;
}
.CourseInfo__Hcontact--oea {
  background: white;
}
.CourseInfo__content {
  margin: 20px 0;
  text-align: center;
  background: #f5f5f5;
  padding: 20px 30px;
  border-radius: 35px;
}
.ListCard {
  margin-bottom: 20px;
}
.ListCard .H4 {
  text-transform: uppercase;
}
.ListCard hr {
  border: 1px solid var(--green);
}
.CourseInfo__Content .H2 {
  text-transform: uppercase;
}
.CardLesson {
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid var(--black);
}
.CardModule {
  margin: 5px 0;
  color: white;
}
.CardModule__card {
  padding: 10px 15px;
  /* background: var(--yellow); */
  border-radius: 10px;
  cursor: pointer;
}
.CardModule__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}
.ContactHeader__btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CourseInfo__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.CourseInfo__btn .DefaultBtn {
  padding: 10px;
}
.CourseInfo__Teachers {
  margin: 10px;
}
.CourseInfo__cadTeachers {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
}
.InfoOEA__teachers {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.TeacherOEA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  transform: translateY(-25px);
}
.TeacherOEA__img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-size: cover;
  border: 4px solid var(--red);
  transform: translateY(50px);
}
.TeacherOEA__card {
  background: #80808020;
  padding: 10px 15px;
  border-radius: 15px;
}
.TeacherOEA__text {
  margin: 0;
  margin-top: 50px;
  text-align: center;
  font-size: 2rem;
}
@media screen and (max-width: 768px) {
  .CourseInfo__header {
    flex-direction: column;
  }
  .CourseInfo__header > div {
    width: auto;
  }
}
@media screen and (max-width: 425px) {
  .CourseInfo {
    margin-top: 200px;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .CourseInfo--oea {
    margin-top: 0;
  }
}
