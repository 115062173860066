.ButtonNavigation {
  width: 98%;
  background: rgb(230, 230, 230);
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.ButtonNavigation button {
  border: none;
  cursor: pointer;
  color: gray;
  font-weight: 600;
  padding: 7px;
  border-radius: 8px;
}
.ButtonNavigation .ButtonNavigation__activate {
  background: var(--white);
  color: var(--orange2);
}
.ButtonNavigation .ButtonNavigation__activate2{
  background: var(--green);
  color: var(--white);
}
@media screen and (max-width: 576px) {
  .ButtonNavigation {
    flex-direction: column;
    height: auto;
    gap: 5px;
  }
  .ButtonNavigation button {
    width: 100% !important;
  }
}
